// ExpansionPanel

/// The vertical spacing of the ExpansionPanel.
/// @group expander
$kendo-expander-spacing-y: k-spacing(3) !default;
/// The width of the border around the ExpansionPanel.
/// @group expander
$kendo-expander-border-width: 1px !default;
/// The font family of the ExpansionPanel.
/// @group expander
$kendo-expander-font-family: var( --kendo-font-family, inherit ) !default;
/// The font size of the ExpansionPanel.
/// @group expander
$kendo-expander-font-size: var( --kendo-font-size, inherit ) !default;
/// The hine height of the ExpansionPanel.
/// @group expander
$kendo-expander-line-height: var( --kendo-line-height, normal ) !default;

/// The text color of the ExpansionPanel.
/// @group expander
$kendo-expander-text: $kendo-component-text !default;
/// The background color of the ExpansionPanel.
/// @group expander
$kendo-expander-bg: $kendo-component-bg !default;
/// The border color of the ExpansionPanel.
/// @group expander
$kendo-expander-border: $kendo-component-border !default;

/// The box shadow of the focused ExpansionPanel.
/// @group expander
$kendo-expander-focus-shadow: inset 0 0 0 2px rgba( black, .08 ) !default;

/// The horizontal padding of the ExpansionPanel header.
/// @group expander
$kendo-expander-header-padding-x: k-spacing(6) !default;
/// The vertical padding of the ExpansionPanel header.
/// @group expander
$kendo-expander-header-padding-y: k-spacing(3) !default;

/// The text color of the ExpansionPanel header.
/// @group expander
$kendo-expander-header-text: $kendo-expander-text !default;
/// The background color of the ExpansionPanel header.
/// @group expander
$kendo-expander-header-bg: transparent !default;
/// The border color of the ExpansionPanel header.
/// @group expander
$kendo-expander-header-border: null !default;

/// The background color of the hovered ExpansionPanel header.
/// @group expander
$kendo-expander-header-hover-bg: rgba( black, .04 ) !default;
/// The background color of the focused ExpansionPanel header.
/// @group expander
$kendo-expander-header-focus-bg: rgba( black, .12 ) !default;
/// The box shadow of the focused ExpansionPanel header.
/// @group expander
$kendo-expander-header-focus-shadow: none !default;

/// The text color of the ExpansionPanel title.
/// @group expander
$kendo-expander-title-text: $kendo-color-secondary !default;

/// The text color of the ExpansionPanel sub-title.
/// @group expander
$kendo-expander-header-sub-title-text: $kendo-subtle-text !default;

/// The horizontal margin of the ExpansionPanel indicator.
/// @group expander
$kendo-expander-indicator-margin-x: k-spacing(3) !default;

/// The horizontal padding of the ExpansionPanel content.
/// @group expander
$kendo-expander-content-padding-x: k-spacing(6) !default;
/// The vertical padding of the ExpansionPanel content.
/// @group expander
$kendo-expander-content-padding-y: k-spacing(6) !default;
