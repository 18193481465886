@import "@progress/kendo-theme-default/scss/radio/_layout.scss";


@mixin kendo-radio--layout() {

    @include kendo-radio--layout-base();

    .k-radio::before {
        transition: transform linear 280ms;
        transform-origin: 0 0;
    }

}
