@import "@progress/kendo-theme-default/scss/editor/_layout.scss";


@mixin kendo-editor--layout() {

    @include kendo-editor--layout-base();

    // Insert table
    .k-ct-popup {
        line-height: normal;

        .k-status {
            line-height: var( --kendo-line-height, inherit);
        }
    }

    .k-editor-export {
        text-transform: uppercase;
        font-size: var( --kendo-font-size, inherit );

        .k-icon {
            margin-left: $kendo-editor-export-tool-icon-margin-x;
        }
    }
}
