// Rating

/// The font family of the Rating.
/// @group rating
$kendo-rating-font-family: var( --kendo-font-family, inherit ) !default;
/// The font size of the Rating.
/// @group rating
$kendo-rating-font-size: var( --kendo-font-size, inherit ) !default;
/// The line height of the Rating.
/// @group rating
$kendo-rating-line-height: var( --kendo-line-height, normal ) !default;

/// The horizontal margin of the Rating container.
/// @group rating
$kendo-rating-container-margin-x: calc( #{$kendo-padding-sm-x} / 2 ) !default;

/// The horizontal padding of the Rating item.
/// @group rating
$kendo-rating-item-padding-x: calc( #{$kendo-padding-sm-x} / 2 ) !default;
/// The vertical padding of the Rating item.
/// @group rating
$kendo-rating-item-padding-y: $kendo-padding-md-y !default;

/// The horizontal margin of the Rating label.
/// @group rating
$kendo-rating-label-margin-x: $kendo-padding-sm-x !default;
/// The vertical margin of the Rating label.
/// @group rating
$kendo-rating-label-margin-y: $kendo-padding-md-y !default;
/// The line height of the Rating label.
/// @group rating
$kendo-rating-label-line-height: $kendo-line-height-lg !default;

/// The text color of the Rating icon.
/// @group rating
$kendo-rating-icon-text: $kendo-subtle-text !default;
/// The text color of the selected Rating icon.
/// @group rating
$kendo-rating-icon-selected-text: $kendo-color-primary-darker !default;
/// The text color of the hovered Rating icon.
/// @group rating
$kendo-rating-icon-hover-text: $kendo-color-primary-darker !default;
/// The text color of the focused Rating icon.
/// @group rating
$kendo-rating-icon-focus-text: $kendo-color-primary-darker !default;

/// The shadow of the focused Rating icon.
/// @group rating
$kendo-rating-icon-focus-shadow: 0 2px 4px rgba( black, .1 ) !default;
/// The shadow of the focused and selected Rating icon.
/// @group rating
$kendo-rating-icon-focus-selected-shadow: 0 2px 4px rgba( black, .1) !default;
