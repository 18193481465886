@import "@progress/kendo-theme-default/scss/pager/_layout.scss";


@mixin kendo-pager--layout() {

    @include kendo-pager--layout-base();

    // Pager
    .k-pager {

        .k-pager-numbers-wrap {
            .k-button {
                @include border-radius( $kendo-pager-number-border-radius );
            }
        }

    }

    .k-pager-numbers {
        .k-button {
            padding-inline: $kendo-button-padding-y;
        }
    }

}
