@import "@progress/kendo-theme-default/scss/dialog/_layout.scss";


@mixin kendo-dialog--layout() {

    @include kendo-dialog--layout-base();

    // Dialog
    .k-dialog-content.k-scroll {
        border-width: 1px 0;
        border-style: solid;
        padding-top: #{k-math-div( $kendo-window-inner-padding-y, 2 )};
        padding-bottom: #{k-math-div( $kendo-window-inner-padding-y, 2 )};
    }

}
