@import "@progress/kendo-theme-default/scss/list/_theme.scss";


@mixin kendo-list--theme() {
    @include kendo-list--theme-base();

    // List item
    .k-list-item {
        &.k-selected:hover,
        &.k-selected.k-hover {
            background-color: if($kendo-enable-color-system, k-color( base-active ), k-color-shade( $kendo-list-item-selected-bg ));
        }
    }
}
