@mixin kendo-time-marker--theme-base() {

    .k-current-time {
        background: $kendo-current-time-color;

        &.k-current-time-arrow-left {
            border-right-color: $kendo-current-time-color;
        }

        &.k-current-time-arrow-right {
            border-left-color: $kendo-current-time-color;
        }

        &.k-current-time-arrow-down {
            border-top-color: $kendo-current-time-color;
        }
    }

}


@mixin kendo-time-marker--theme() {
    @include kendo-time-marker--theme-base();
}
