@mixin kendo-core--styles--selection() {

    // Marquee
    .k-marquee {
        position: absolute;
        z-index: 100000;
    }

    .k-marquee-color,
    .k-marquee-text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .k-marquee-color {
        color: k-get-theme-color-var( neutral-160, #{$kendo-selected-text} );
        background-color: k-get-theme-color-var( primary-60, #{$kendo-selected-bg} );
        border-color: k-get-theme-color-var( primary-100, #{$kendo-selected-border} );
        opacity: .6;
    }
    .k-marquee-text {
        color: k-get-theme-color-var( neutral-160, #{$kendo-selected-text} );
    }

    // Text selection
    .k-text-selection {
        ::selection {
            background-color:  if($kendo-enable-color-system, k-color( primary ), k-get-theme-color-var( primary-100 ));
            color: if($kendo-enable-color-system, k-color( on-primary ), $kendo-selected-text);
        }
    }

}
