// Chart wizard

/// The color of the area around the chart type icon.
/// @group chart-wizard
$kendo-chart-wizard-icon-area-color: if($kendo-enable-color-system, k-color(primary), $kendo-color-primary) !default;
/// The background color of the area around the chart type icon.
/// @group chart-wizard
$kendo-chart-wizard-icon-area-bg: if($kendo-enable-color-system, k-color(primary-subtle), k-try-tint( $kendo-color-primary, 80% )) !default;
/// The border radius of the area around the chart type icon.
/// @group chart-wizard
$kendo-chart-wizard-icon-area-border-radius: k-border-radius(none) !default;
/// The padding of the area around the chart type icon.
/// @group chart-wizard
$kendo-chart-wizard-icon-area-padding: k-spacing(4) !default;
/// The box shadow of the focused area around the chart type icon.
/// @group chart-wizard
$kendo-chart-wizard-icon-area-focus-shadow: inset 0 0 0 1px if($kendo-enable-color-system, k-color(primary-emphasis), k-try-tint( $kendo-color-primary, 25% )) !default;
/// The box shadow of the selected area around the chart type icon.
/// @group chart-wizard
$kendo-chart-wizard-icon-area-selected-shadow: inset 0 0 0 1px $kendo-chart-wizard-icon-area-color !default;

/// The color of the selected chart type items in the Property panel.
/// @group chart-wizard
$kendo-chart-wizard-chart-type-selected-color: if($kendo-enable-color-system, k-color(primary), $kendo-color-primary) !default;

/// The padding of the preview pane.
/// @group chart-wizard
$kendo-chart-wizard-preview-pane-padding: k-spacing(4) !default;
/// The padding of the property pane.
/// @group chart-wizard
$kendo-chart-wizard-property-pane-padding: 0 !default;

/// The gap between the chart type items in the Property panel.
/// @group chart-wizard
$kendo-chart-wizard-chart-type-spacing: k-spacing(6) !default;
/// The gap between the icon area and its text.
/// @group chart-wizard
$kendo-chart-wizard-icon-text-gap: k-spacing(3) !default;
