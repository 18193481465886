@import "@progress/kendo-theme-default/scss/chat/_theme.scss";


@mixin kendo-chat--theme() {
    @include kendo-chat--theme-base();

    .k-chat .k-quick-reply {
        &:focus,
        &.k-focus {
            color: if($kendo-enable-color-system, k-color( primary-on-surface ), $kendo-color-primary);
        }
    }
}
