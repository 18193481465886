@import "@progress/kendo-theme-default/scss/checkbox/_theme.scss";


@mixin kendo-checkbox--theme() {

    @include kendo-checkbox--theme-base();

    // Checkbox
    .k-checkbox-label {

        // Disabled state
        .k-checkbox:disabled + & {
            color: if($kendo-enable-color-system, k-color( subtle ), if( $kendo-is-dark-theme, $light-secondary-text, $dark-secondary-text ));
            opacity: 1;
            filter: none;
        }
    }

}
