@import "@progress/kendo-theme-default/scss/gantt/_theme.scss";


@mixin kendo-gantt--theme() {

    @include kendo-gantt--theme-base();

    // Timeline
    .k-gantt-timeline-pane {

        tbody > tr:not(:only-child) > .k-header {
            border-left-color: $kendo-grid-border;
        }
        .k-rtl & {

            .k-grid-header {

                tbody > tr:not(:only-child) > .k-header {
                    border-right-color: $kendo-grid-border;
                }
            }
        }
    }

}
