@use '../styles/abstracts/variables' as var;
@use '../styles/abstracts/variables_brand' as brand;

// Enter Kendo UI Material Theme over-rides here.
// They must be placed before the import call to "@progress/kendo-theme-material/scss/all".
// You can find the Theme variables at node_modules/@progress/kendo-theme-material/scss/*
$kendo-color-primary: var.$cgi-color-cgi-purple;
$kendo-color-secondary: var.$cgi-color-dark-purple;
$kendo-color-tertiary: var.$cgi-color-purple-medium;

$kendo-drawer-bg: var.$cgi-color-action-link-bg;
$kendo-drawer-selected-bg: var.$cgi-color-cgi-purple;
$kendo-drawer-selected-text: rgb(255, 255, 255);
$kendo-drawer-selected-hover-bg: var.$cgi-color-purple-vivid;
$kendo-drawer-selected-hover-text: rgb(255, 255, 255);
$kendo-drawer-hover-bg: var.$cgi-color-purple-vivid;
$kendo-drawer-hover-text: rgb(255, 255, 255);
$kendo-drawer-focus-bg: var.$cgi-color-cgi-purple;

$kendo-color-error: var.$cgi-color-error;
$kendo-color-warning: var.$cgi-color-warning;
$kendo-color-success: var.$cgi-color-success;

//$kendo-table-alt-row-bg: var.$cgi-color-light-purple;
//$kendo-table-hover-bg: var.$cgi-color-pale-purple;
//$kendo-table-hover-border: var.$cgi-color-medium-purple;


// Right now use 'all.scss' even though this may bloat the code with CSS.
// Hopefully soon Kendo will move their SCSS to the current version where we can use the proper @use/@forward statements.
@import "@progress/kendo-theme-material/scss/all";


// Over-rides:
// This class is for the user's profile button at the top of the page.  For some reason this over-ride class will not work
// in the component where the button resides.  It has to be placed higher up; here.
.user-profile-button {
  background: brand.$cgi-gradient;
  color: white;
  border-radius: 50%;
  width: 40px;
  display: flex;
  justify-content: center;
}

// Remove table cell padding when viewed in a small viewport
@media only screen and (max-width: 450px) {
  .k-table-md .k-table-th, .k-table-md .k-table-td, .k-grid-md td, .k-grid-md .k-table-td {
    padding-block: 0;
    padding-inline: 0;
  }
}

// Over-ride Kendo UI Dialog styles
#add-post-dialog {
  .k-window-content {
    padding: 0;
  }

  // This a fix.  When the user finishes the registration process and goes to a page where you can open up a Post dialog, the sensitivity drop down
  // has a z-index of 1000 and .k-dialog-wrapper has a 1001.  The makes the sensitivity dropdown menu items appear behind the dialog.
  // Here, the z-index is pushed behind the menu items.
  &.k-dialog-wrapper {
    z-index: 10;
  }
}
