@import "./_functions.import.scss";

// Config
$kendo-enable-color-system: true !default;

// Colors
$_default_colors: (
    // Misc
    app-surface: #ffffff,
    on-app-surface: #3d3d3d,
    subtle: #666666,
    surface: #fafafa,
    surface-alt: #ffffff,
    border: rgba(0, 0, 0, 0.08),
    border-alt: rgba(0, 0, 0, 0.16),
    // Base
    base-subtle: #ebebeb,
    base-subtle-hover: #e0e0e0,
    base-subtle-active: #d6d6d6,
    base: #f5f5f5,
    base-hover: #ebebeb,
    base-active: #d6d6d6,
    base-emphasis: #c2c2c2,
    base-on-subtle: #3d3d3d,
    on-base: #3d3d3d,
    base-on-surface: #3d3d3d,
    // Primary
    primary-subtle: #ffeceb,
    primary-subtle-hover: #ffdedb,
    primary-subtle-active: #ffc8c4,
    primary: #ff6358,
    primary-hover: #ea5a51,
    primary-active: #d45349,
    primary-emphasis: #ff9d97,
    primary-on-subtle: #5c201c,
    on-primary: #ffffff,
    primary-on-surface: #ff6358,
    // Secondary
    secondary-subtle: #fafafa,
    secondary-subtle-hover: #f5f5f5,
    secondary-subtle-active: #ebebeb,
    secondary: #666666,
    secondary-hover: #525252,
    secondary-active: #3d3d3d,
    secondary-emphasis: #e0e0e0,
    secondary-on-subtle: #141414,
    on-secondary: #ffffff,
    secondary-on-surface: #292929,
    // Tertiary
    tertiary-subtle: #d8f1fd,
    tertiary-subtle-hover: #c5eafc,
    tertiary-subtle-active: #a3dffb,
    tertiary: #03a9f4,
    tertiary-hover: #039ae0,
    tertiary-active: #028ccb,
    tertiary-emphasis: #61c9f9,
    tertiary-on-subtle: #023f5c,
    on-tertiary: #ffffff,
    tertiary-on-surface: #028ccb,
    // Info
    info-subtle: #d2e2fb,
    info-subtle-hover: #bdd4f8,
    info-subtle-active: #80acf4,
    info: #0058e9,
    info-hover: #0052d6,
    info-active: #004ac2,
    info-emphasis: #6098f2,
    info-on-subtle: #002259,
    on-info: #ffffff,
    info-on-surface: #004ac2,
    // Success
    success-subtle: #dcf0d3,
    success-subtle-hover: #cbe9bf,
    success-subtle-active: #b7e1a5,
    success: #37b400,
    success-hover: #32a500,
    success-active: #2d9600,
    success-emphasis: #81d15f,
    success-on-subtle: #1c5a00,
    on-success: #ffffff,
    success-on-surface: #2d9600,
    // Warning
    warning-subtle: #fff4d3,
    warning-subtle-hover: #ffeebd,
    warning-subtle-active: #ffe79e,
    warning: #ffc000,
    warning-hover: #eaaf00,
    warning-active: #d49f00,
    warning-emphasis: #ffd760,
    warning-on-subtle: #5e4700,
    on-warning: #3d3d3d,
    warning-on-surface: #ffc000,
    // Error
    error-subtle: #fcddda,
    error-subtle-hover: #fbc8c3,
    error-subtle-active: #f98b80,
    error: #f31700,
    error-hover: #df1600,
    error-active: #ca1400,
    error-emphasis: #f76f60,
    error-on-subtle: #7a0c00,
    on-error: #ffffff,
    error-on-surface: #ca1400,
    // Light
    light-subtle: #fafafa,
    light-subtle-hover: #f5f5f5,
    light-subtle-active: #ebebeb,
    light: #ebebeb,
    light-hover: #e0e0e0,
    light-active: #d6d6d6,
    light-emphasis: #d6d6d6,
    light-on-subtle: #141414,
    on-light: #000000,
    light-on-surface: #e0e0e0,
    // Dark
    dark-subtle: #c2c2c2,
    dark-subtle-hover: #adadad,
    dark-subtle-active: #999999,
    dark: #3d3d3d,
    dark-hover: #292929,
    dark-active: #1f1f1f,
    dark-emphasis: #666666,
    dark-on-subtle: #1f1f1f,
    on-dark: #ffffff,
    dark-on-surface: #141414,
    // Inverse
    inverse-subtle: #c2c2c2,
    inverse-subtle-hover: #adadad,
    inverse-subtle-active: #999999,
    inverse: #3d3d3d,
    inverse-hover: #292929,
    inverse-active: #1f1f1f,
    inverse-emphasis: #666666,
    inverse-on-subtle: #1f1f1f,
    on-inverse: #ffffff,
    inverse-on-surface: #141414,
    // Series A
    series-a: #ff6358,
    series-a-bold: #bf4a42,
    series-a-bolder: #80322c,
    series-a-subtle: #ffb1ac,
    series-a-subtler: #ff8a82,
    // Series B
    series-b: #ffe162,
    series-b-bold: #bfa94a,
    series-b-bolder: #807131,
    series-b-subtle: #fff0b1,
    series-b-subtler: #ffe989,
    // Series C
    series-c: #4cd180,
    series-c-bold: #399d60,
    series-c-bolder: #266940,
    series-c-subtle: #a6e8c0,
    series-c-subtler: #79dda0,
    // Series D
    series-d: #4b5ffa,
    series-d-bold: #3847bc,
    series-d-bolder: #26307d,
    series-d-subtle: #a5affd,
    series-d-subtler: #7887fb,
    // Series E
    series-e: #ac58ff,
    series-e-bold: #8142bf,
    series-e-bolder: #562c80,
    series-e-subtle: #d6acff,
    series-e-subtler: #c182ff,
    // Series F
    series-f: #ff5892,
    series-f-bold: #bf426e,
    series-f-bolder: #802c49,
    series-f-subtle: #ffacc9,
    series-f-subtler: #ff82ae,
) !default;

/// The global default Colors map.
/// @group color-system
$kendo-colors: $_default-colors !default;
$kendo-colors: k-map-merge($_default-colors, $kendo-colors);

@mixin color-system--styles() {
    :root {
        @each $key, $value in $kendo-colors {
            @if($value) {
                --kendo-color-#{$key}: #{$value};
            }
        }
    }
}
