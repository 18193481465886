@import "@progress/kendo-theme-default/scss/timeselector/_theme.scss";


@mixin kendo-time-selector--theme() {

    @include kendo-time-selector--theme-base();

    // Time selector
    .k-timeselector {}


    // Time list
    .k-time-list {
        .k-item:hover {
            @include fill(
                $kendo-list-item-hover-text,
                $kendo-list-item-hover-bg
            );
        }
    }

}
