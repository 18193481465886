@use '@angular/material' as mat;
@use "../mixins/mixins" as mx;
@use "./app-palette" as palette;


// CGI Experience Design System
// Visit the site for guidelines on their usage
// https:// ensembleca.ent.cgi.com/business/333335/SitePages/index.html#/home
$cgi-color-dark-purple: rgb(32, 10, 88);
$cgi-color-cgi-purple: rgb(82, 54, 171);
$cgi-color-purple-vivid: rgb(158, 131, 245);
$cgi-color-purple-medium: rgb(203, 195, 230);
$cgi-color-purple-lighter-light: rgb(230, 227, 243);
$cgi-color-purple-lightest-light: rgb(242, 241, 249);
$cgi-color-action-link-text-color: rgb(21, 21, 21);
$cgi-color-default-link-text-color: rgb(51, 51, 51);
$cgi-color-dark-gray: rgb(168, 168, 168);
$cgi-color-action-link-bg: rgb(232, 232, 232);
$cgi-color-gray-hero: rgb(239, 239, 239);
$cgi-color-gray-bg: rgb(248, 248, 248);
$cgi-color-success: rgb(26, 185, 119);
$cgi-color-error: rgb(176, 0, 32);
$cgi-color-warning: rgb(255, 171, 37);
$cgi-color-logo-red: rgb(229, 28, 58);
$cgi-color-red-shade-0: rgb(101, 10, 33);
$cgi-color-red-shade-1: rgb(153, 31, 61);
$cgi-color-red-shade-2: rgb(227, 25, 55);
$cgi-color-red-shade-3: rgb(255, 106, 0);
$cgi-color-red-shade-4: rgb(255, 115, 98);
$cgi-color-red-shade-5: rgb(255, 151, 138);
$cgi-color-red-shade-6: rgb(255, 205, 210);
$cgi-color-gray-shade-0: rgb(0, 0, 0);
$cgi-color-gray-shade-1: rgb(51, 51, 51);
$cgi-color-gray-shade-2: rgb(85, 85, 85);
$cgi-color-gray-shade-3: rgb(119, 119, 119);
$cgi-color-gray-shade-4: rgb(153, 153, 153);
$cgi-color-gray-shade-5: rgb(204, 204, 204);
$cgi-color-gray-shade-6: rgb(238, 238, 238);
$cgi-color-magenta-shade-0: rgb(126, 27, 76);
$cgi-color-magenta-shade-1: rgb(168, 36, 101);
$cgi-color-magenta-shade-2: rgb(203, 124, 163);
$gradient-horizontal: linear-gradient(270deg,#5236ab 0%,#e41937 100%);
// END CGI Experience Design System



// Colors are based on CGI IP DPL
// https:// alm.cgifederal.com/scm/pages/UXDPL/etp-ip-dpl/integration/browse/examples/colors.html
$cgif-color-app-primary: rgb(153, 31, 61);           //  #991f3d;
$cgif-color-app-primary-lighter: rgb(224, 188, 197); //  #e0bcc5;
$cgif-color-app-primary-darker: rgb(124, 18, 39);    //  #7c1227;
$cgif-color-app-accent: rgb(201, 228, 237);          //  #99bfcc;
$cgif-color-app-accent-lighter: rgb(224, 236, 240);  //  #e0ecf0;
$cgif-color-app-accent-darker: rgb(124, 169, 185);   //  #7ca9b9;
$cgif-color-app-warn: rgb(255, 0, 0);                //  #ff0000;
$cgif-color-app-warn-lighter: rgb(255, 179, 179);    //  #ffb3b3;
$cgif-color-extended-cloud-1: rgb(43, 48, 51);      //  #2b3033
$cgif-color-extended-cloud-2: rgb(80, 86, 89);      //  #505659
$cgif-color-extended-cloud-3: rgb(115, 123, 128);   //  #737B80
$cgif-color-extended-cloud-4: rgb(170, 175, 179);   //  #AAAFB3
$cgif-color-extended-cloud-5: rgb(206, 212, 217);   //  #ced4d9
$cgif-color-extended-cloud-6: rgb(228, 232, 235);   //  #e4e8eb
$cgif-color-extended-beet-1: rgb(102, 10, 33);      //  #660a21
$cgif-color-extended-beet-2: rgb(153, 31, 61);      //  #991f3d
$cgif-color-extended-beet-3: rgb(230, 218, 221);    //  #e6dadd
$cgif-color-white: rgb(255, 255, 255);

//  ***** Link Styles *****
// Light Background links
$cgif-color-link_light-bg: rgb(46, 96, 115);                   //  #2e6073;
$cgif-color-link-visited_light-bg: $cgif-color-link_light-bg;
$cgif-color-link-hover_light-bg: rgb(32, 66, 78);              //  #20424e | Underline
$cgif-color-link-focus_light-bg: $cgif-color-link_light-bg;

// Dark Background links
$cgif-color-link_dark-bg: rgb(255, 255, 255);                   //  #2e6073;
$cgif-color-link-visited_dark-bg: $cgif-color-link_dark-bg;
$cgif-color-link-hover_dark-bg: rgb(228, 232, 235);              //  #20424e | Underline
$cgif-color-link-focus_dark-bg: $cgif-color-link_dark-bg;
//   ***** END Link Styles *****

// Alert colors
$cgif-color-success_light: rgb(237, 242, 222);        //  #edf2de;
$cgif-color-info_light: rgb(218, 236, 242);           //  #daecf2;
$cgif-color-warning_light: rgb(253, 247, 220);        //  #fdf7dc;
$cgif-color-error_light: rgb(242, 206, 211);          //  #f2ced3;
$cgif-color-success_dark: rgb(0, 117, 53);            //  #007535;
$cgif-color-info_dark: rgb(107, 161, 179);            //  #6ba1b3;
$cgif-color-warning_dark: rgb(255, 106, 0);           //  #ff6a00;
$cgif-color-error_dark: rgb(227, 25, 55);             //  #e31937;
// END CGI IP DPL

// $button-primary-color: $cgif-color-app-primary;
// $button-secondary-color: mat.m2-get-color-from-palette(palette.$cgi-ice-palette, 500);       //  #407080;
// $button-default-color: rgb(228, 232, 235);       //  #e4e8eb;

// TYPOGRAPHY
// These sizes come from CGI Experience Design System
// https://brand.cgi.com/content/index/guid/typography_1?parent=288#anchor0
$font-size-h1: 38px;
$font-size-h2: 28px;
$font-size-h3: 24px;
$font-size-h4: 20px;
$font-size-h5: 18px;
$font-size-h6: 16px;
$font-size-body: 16px;
$font-size-description-text: 14px;
$font-size-list-item: 16px;
$font-size-action-link: 14px;
$font-size-hero-action: 14px;
$font-size-button: 18px;
$font-size-quotes: 26px;

$form-element-vertical-spacer: 15px;
