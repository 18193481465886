// Responsive
$mat-xsmall: 'max-width: 600px';
$mat-small: 'max-width: 960px';
//CGI-Brand Colors
//Primary Colors
// 1. Purple
$mat-cgi-charcoal: (
  50: #F2F1F9,
  100: #E6E3F3,
  200: #CBC3E6,
  300: #AFA3D8,
  400: #9E83F5,
  500: rgb(117, 94, 188),
  600: #5236AB,
  700: #3A2679,
  800: #2D1E5E,
  900: #200a58,
  A100: #5236AB,
  A200: #5236AB,
  A400: #5236AB,
  A700: #5236AB,
  contrast: (
    50: #5236AB,
    100: #5236AB,
    200: #5236AB,
    300: #5236AB,
    400: #5236AB,
    500: #5236AB,
    600: #5236AB,
    700: #ffffff,
    800: #5236AB,
    900: #5236AB,
    A100: #5236AB,
    A200: #5236AB,
    A400: #5236AB,
    A700: #5236AB,
  ),
);

// 2. Red
$mat-cgi-red: (
  50: #FCE8EB,
  100: #F7B7C1,
  200: #F395A3,
  300: #ED6479,
  400: #E9465F,
  500: #E31937,
  600: #CF1632,
  700: #A21127,
  800: #7D0D1E,
  900: #600A17,
  A100: #E31937,
  A200: #E31937,
  A400: #E31937,
  A700: #E31937,
  contrast: (
    50: #E31937,
    100: #E31937,
    200: #E31937,
    300: #E31937,
    400: #E31937,
    500: #E31937,
    600: #E31937,
    700: #E31937,
    800: #E31937,
    900: #E31937,
    A100: #E31937,
    A200: #E31937,
    A400: #E31937,
    A700: #E31937,
  ),
);

// 3. Gray
$mat-cgi-gray: (
  50: #EFEFEF,
  100: #C0C0C0,
  200: #A8A8A8,
  300: #767676,
  400: #5C5C5C,
  500: #333333,
  600: #2E2E2E,
  700: #242424,
  800: #1C1C1C,
  900: #151515,
  A100: #333333,
  A200: #333333,
  A400: #333333,
  A700: #333333,
  contrast: (
    50: #333333,
    100: #333333,
    200: #333333,
    300: #333333,
    400: #333333,
    500: #333333,
    600: #333333,
    700: #333333,
    800: #333333,
    900: #333333,
    A100: #333333,
    A200: #333333,
    A400: #333333,
    A700: #333333,
  ),
);

// 4. White
$mat-cgi-white: (
  50: #ffffff,
  100: #FCFDFD,
  200: #F9FAFB,
  300: #F6F8F9,
  400: #F5F7F9,
  500: #F2F4F7,
  600: #F0F3F6,
  700: #EBEEF2,
  800: #ffffff,
  900: #ffffff,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffffff,
  A700: #ffffff,
  contrast: (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

//5. Cloud
$mat-cgi-cloud: (
  50: #fbfcfc,
  100: #f6f7f7,
  200: #e9ebed,
  300: #d6d9db,
  400: #ced2d4,
  500: #bdc2c5,
  600: #a5acb0,
  700: #888d91,
  800: #696c6e,
  900: #3c3f40,
  A100: #3c3f40,
  A200: #3c3f40,
  A400: #3c3f40,
  A700: #3c3f40,
  contrast: (
    50: #a5acb0,
    100: #a5acb0,
    200: #a5acb0,
    300: #a5acb0,
    400: #a5acb0,
    500: #a5acb0,
    600: #a5acb0,
    700: #a5acb0,
    800: #a5acb0,
    900: #a5acb0,
    A100: #a5acb0,
    A200: #a5acb0,
    A400: #a5acb0,
    A700: #a5acb0,
  ),
);

//6. Seablue
$mat-cgi-seablue: (
  50: #f3f8fa,
  100: #e7f2f6,
  200: #d0e4eb,
  300: #bdd9e3,
  400: #a2cad8,
  500: #73b0c5,
  600: #2e89a8,
  700: #006f95,
  800: #00516d,
  900: #003344,
  A100: #003344,
  A200: #003344,
  A400: #003344,
  A700: #003344,
  contrast: (
    50: #000019,
    100: #000019,
    200: #000019,
    300: #000019,
    400: #000019,
    500: #000019,
    600: #000019,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

//7. Beet
$mat-cgi-beet: (
  50: #faf4f6,
  100: #f5eaed,
  200: #ecd6db,
  300: #e3c1ca,
  400: #d099a6,
  500: #be7083,
  600: #ab4760,
  700: #991f3d,
  800: #70172d,
  900: #4e212c,
  A100: #4e212c,
  A200: #4e212c,
  A400: #4e212c,
  A700: #4e212c,
  contrast: (
    50: #ab4760,
    100: #ab4760,
    200: #ab4760,
    300: #ab4760,
    400: #ab4760,
    500: #ab4760,
    600: #ab4760,
    700: #ab4760,
    800: #ab4760,
    900: #ab4760,
    A100: #ab4760,
    A200: #ab4760,
    A400: #ab4760,
    A700: #ab4760,
  ),
);

//Secondary
// 1. Green
$mat-cgi-sec-green: (
  50: #E8F8F1,
  100: #B8E9D5,
  200: #96DFC0,
  300: #66D0A4,
  400: #48C792,
  500: #1AB977,
  600: #18A86C,
  700: #128354,
  800: #0E6641,
  900: #0B4E32,
  A100: #1AB977,
  A200: #1AB977,
  A400: #1AB977,
  A700: #1AB977,
  contrast: (
    50: #1AB977,
    100: #1AB977,
    200: #1AB977,
    300: #1AB977,
    400: #1AB977,
    500: #1AB977,
    600: #1AB977,
    700: #1AB977,
    800: #1AB977,
    900: #1AB977,
    A100: #1AB977,
    A200: #1AB977,
    A400: #1AB977,
    A700: #1AB977,
  ),
);

// 2. Warning
$mat-cgi-sec-pumpkin: (
  50: #FEF6E9,
  100: #FBE3BB,
  200: #F9D59B,
  300: #F6C26D,
  400: #F4B651,
  500: #F1A425,
  600: #DB9522,
  700: #AB741A,
  800: #855A14,
  900: #654510,
  A100: #F1A425,
  A200: #F1A425,
  A400: #F1A425,
  A700: #F1A425,
  contrast: (
    50: #F1A425,
    100: #F1A425,
    200: #F1A425,
    300: #F1A425,
    400: #F1A425,
    500: #F1A425,
    600: #F1A425,
    700: #F1A425,
    800: #F1A425,
    900: #F1A425,
    A100: #F1A425,
    A200: #F1A425,
    A400: #F1A425,
    A700: #F1A425,
  ),
);

// 3. Error
$mat-cgi-sec-red: (
  50: #F7E6E9,
  100: #E7B0BA,
  200: #DB8A98,
  300: #CA546A,
  400: #C0334D,
  500: #B00020,
  600: #A0001D,
  700: #7D0017,
  800: #610012,
  900: #4A000D,
  A100: #B00020,
  A200: #B00020,
  A400: #B00020,
  A700: #B00020,
  contrast: (
    50: #B00020,
    100: #B00020,
    200: #B00020,
    300: #B00020,
    400: #B00020,
    500: #B00020,
    600: #B00020,
    700: #B00020,
    800: #B00020,
    900: #B00020,
    A100: #B00020,
    A200: #B00020,
    A400: #B00020,
    A700: #B00020,
  ),
);

//4. Magenta
$mat-cgi-sec-magenta: (
  50: #A82465,
  100: #A82465,
  200: #CB7CA3,
  300: #A82465,
  400: #A82465,
  500: #A82465,
  600: #7E1B4C,
  700: #A82465,
  800: #A82465,
  900: #A82465,
  A100: #A82465,
  A200: #A82465,
  A400: #A82465,
  A700: #A82465,
  contrast: (
    50: #A82465,
    100: #A82465,
    200: #A82465,
    300: #A82465,
    400: #A82465,
    500: #A82465,
    600: #A82465,
    700: #A82465,
    800: #A82465,
    900: #A82465,
    A100: #A82465,
    A200: #A82465,
    A400: #A82465,
    A700: #A82465,
  ),
);

// Toolbar
$mat-toolbar-height-desktop: 60px !default;
$mat-toolbar-width-desktop: 4.4rem !default;
$mat-toolbar-height-mobile: 3.5rem !default;
$mat-toolbar-width-mobile: 3.5rem !default;
// SideNav
$cgi-sidenav-width-collapse: 0rem !default;
$cgi-sidenav-width-full: 18rem !default;
$cgi-sidenav-footer-margin: 13.6rem !default;
// Forms
$cgi-forms-text-color: #212529;

//Chip disable
$cgi-chip-disable: #15151573;

//Navigation
$cgi-gradient-vertical: linear-gradient(to bottom, #e31937, #5236ab) 0 100%;
$cgi-gradient-border: linear-gradient(to right, #e31937 100%, #5236ab 100%) 1;

//refer
$cgi-gradient: linear-gradient(to right, #e31937, #5236ab);
$cgi-gradient-a: linear-gradient(to right, #5236ab, #e31937);
$cgi-gradient-b-vertical-new: linear-gradient(90deg, #991F3D 0%, #E31937 30.73%, #FF6A00 64.06%, #FFCDD2 100%);

$cgi-material-primary-1: $mat-cgi-charcoal !default;
$cgi-material-primary-2: $mat-cgi-red !default;
$cgi-material-primary-3: $mat-cgi-gray !default;
$cgi-material-primary-4: $mat-cgi-white !default;
$cgi-material-primary-5: $mat-cgi-cloud !default;
$cgi-material-primary-6: $mat-cgi-seablue !default;
$cgi-material-primary-7: $mat-cgi-beet !default;

$cgi-material-secondary-1: $mat-cgi-sec-green !default;
$cgi-material-secondary-2: $mat-cgi-sec-pumpkin !default;
$cgi-material-secondary-3: $mat-cgi-sec-red !default;
$cgi-material-secondary-4: $mat-cgi-sec-magenta !default;
