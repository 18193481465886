@import "@progress/kendo-theme-default/scss/input/_layout.scss";


@mixin kendo-input--layout() {

    @include kendo-input--layout-base();

    // Fill mode
    .k-input-solid,
    .k-picker-solid {
        @include border-bottom-radius( 0 !important ); // stylelint-disable-line declaration-no-important
        border-width: $kendo-input-border-width 0;
        border-top-color: transparent !important; // stylelint-disable-line declaration-no-important

        .k-input-button,
        .k-input-spinner .k-spinner-increase,
        .k-input-spinner .k-spinner-decrease {
            background: none;
            border-color: transparent;
        }
    }


    .k-input-flat,
    .k-picker-flat {

        .k-input-button,
        .k-input-spinner .k-spinner-increase,
        .k-input-spinner .k-spinner-decrease {
            background: none;
            border-color: transparent;
        }

        &:disabled,
        &[disabled],
        &.k-disabled  {
            border-bottom-style: dashed;
        }
    }


    // Disabled
    .k-input,
    .k-picker {

        &:disabled,
        &[disabled],
        &.k-disabled {
            opacity: 1;
            filter: none;
        }

        .k-input-button,
        .k-input-spinner .k-spinner-increase,
        .k-input-spinner .k-spinner-decrease,
        .k-input-values .k-chip {
            color: inherit;
        }
    }


    // Input ripple
    .k-input-solid,
    .k-input-flat,
    .k-input-outline,
    .k-picker-solid,
    .k-picker-flat,
    .k-picker-outline {
        $_kendo-input-ripple-border-width: (2 * $kendo-input-border-width);

        &::after {
            content: "";
            border-width: 0 0 $_kendo-input-ripple-border-width 0;
            border-style: solid;
            border-color: inherit;
            border-radius: inherit;
            display: block;
            position: absolute;
            z-index: 2;
            inset: -1px;
            opacity: 0;
            transform: scaleX(0);
            transition: opacity .3s, transform .3s;
            pointer-events: none;
        }

        &.k-focus::after {
            opacity: 1;
            transform: scaleX(1);
        }
        &:focus-within::after {
            opacity: 1;
            transform: scaleX(1);
        }
    }
    .k-input-outline,
    .k-picker-outline {
        $_kendo-input-ripple-border-width: (2 * $kendo-input-border-width);

        &::after {
            border-width: $_kendo-input-ripple-border-width;
            transform: none;
        }

        &.k-focus::after {
            transform: none;
        }
        &:focus-within::after {
            transform: none;
        }
    }

}
