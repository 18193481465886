@import "@progress/kendo-theme-default/scss/stepper/_layout.scss";


@mixin kendo-stepper--layout() {

    @include kendo-stepper--layout-base();

    // Stepper
    .k-stepper {}


    // Selected step
    .k-step-current {
        .k-step-label {
            font-weight: 400;
        }
    }

}
