@use "../mixins/mixins" as mx;
@use './variables' as var;
@use './variables_brand' as brand;

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Source Sans Pro", "Helvetica Neue", sans-serif;
  font-size: mx.rem(var.$font-size-body);
}

h1 {
  font-size: var.$font-size-h1;
}

h2 {
  font-size: var.$font-size-h2;
}

h3 {
  font-size: var.$font-size-h3;
}

h4 {
  font-size: var.$font-size-h4;
}

h5 {
  font-size: var.$font-size-h5;
}

h6 {
  font-size: var.$font-size-h6;
}

a {
  font-size: var.$font-size-action-link;
  color: var.$cgi-color-cgi-purple;

  &:hover {
    text-decoration: underline;
  }
}


.mat-mdc-snack-bar-container {
  &.snackbar-info {
    .mdc-snackbar__surface {
      background-color: var.$cgi-color-purple-vivid;
    }
  }

  &.snackbar-success {
    .mdc-snackbar__surface {
      background-color: var.$cgi-color-success;
    }
  }

  &.snackbar-warn {
    .mdc-snackbar__surface {
      background-color: var.$cgi-color-warning;
    }
  }

  &.snackbar-error {
    .mdc-snackbar__surface {
      background-color: var.$cgi-color-error;
    }
  }
}

.error-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var.$font-size-h5;

  .error-placeholder-icon {
    color: var.$cgi-color-error;
    margin-right: mx.rem(5px);
  }
}

.secondary-accent-color {
  background-color: var.$cgi-color-purple-medium;
}

.required {
  &::after {
    content: ' *';
    color: var.$cgi-color-logo-red;
  }
}

.avatar {
  // border: var.$cgi-color-dark-purple 2px solid;
  background: brand.$cgi-gradient;  // var.$cgi-color-purple-lighter-light;
  color: white;
  border-radius: 50%;
  width: mx.rem(30px);
  height: mx.rem(30px);
  font-size: mx.rem(16px);
  display: flex;
  justify-content: center;
  align-items: center;

  &.avatar-sm {
    font-size: mx.rem(15px);
    padding: mx.rem(13px);
  }
}

