@import "@progress/kendo-theme-default/scss/bottom-navigation/_theme.scss";

@mixin kendo-bottom-navigation--theme() {

    @include kendo-bottom-navigation--theme-base();

    // Flat
    @each $name, $color in $kendo-theme-colors {
        .k-bottom-nav-flat-#{$name} {
            .k-bottom-nav-item.k-focus,
            .k-bottom-nav-item:focus {
                @include fill( $bg: if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 6%, transparent), rgba($kendo-bottom-nav-flat-text, .05)) );
            }
        }
    }

}
