/* For use in src/lib/core/theming/_palette.scss */
$cgi-purple-palette: (
  50 : #eae7f5,
  100 : #cbc3e6,
  200 : #a99bd5,
  300 : #8672c4,
  400 : #6c54b8,
  500 : #5236ab,
  600 : #4b30a4,
  700 : #41299a,
  800 : #382291,
  900 : #281680,
  A100 : #c1b7ff,
  A200 : #9684ff,
  A400 : #6a51ff,
  A700 : #5437ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$cgi-beet-palette: (
  50 : #e6dadd,
  100 : #e0bcc5,
  200 : #cc8f9e,
  300 : #b86277,
  400 : #a8415a,
  500 : #991f3d,
  600 : #911b37,
  700 : #86172f,
  800 : #7c1227,
  900 : #660a21,
  A100 : #ff9ca9,
  A200 : #ff697c,
  A400 : #ff364f,
  A700 : #ff1d39,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$cgi-cloud-palette: (
  50 : #f5f5f6,
  100 : #e6e7e8,
  200 : #d5d7d9,
  300 : #c4c7ca,
  400 : #b7bbbe,
  500 : #aaafb3,
  600 : #a3a8ac,
  700 : #737b80,
  800 : #505659,
  900 : #2b3033,
  A100 : #ffffff,
  A200 : #e6f3fd,
  A400 : #b1deff,
  A700 : #97d4ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$cgi-ice-palette: (
  50 : #e8eef0,
  100 : #c6d4d9,
  200 : #99bfcc,
  300 : #6ba1b3,
  400 : #537c8a,
  500 : #407080,
  600 : #3a6878,
  700 : #2e6073,
  800 : #20424e,
  900 : #132930,
  A100 : #90dcff,
  A200 : #5dcdff,
  A400 : #2abdff,
  A700 : #11b5ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$cgi-honey-palette: (
  50 : #f2e6ce,
  100 : #fbe3b3,
  200 : #f9d180,
  300 : #f6be4d,
  400 : #f4b026,
  500 : #f2a200,
  600 : #f09a00,
  700 : #ee9000,
  800 : #cc8800,
  900 : #e87500,
  A100 : #ffffff,
  A200 : #ffecdc,
  A400 : #ffcfa9,
  A700 : #ffc190,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);
