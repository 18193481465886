@import "@progress/kendo-theme-default/scss/action-buttons/_theme.scss";


@mixin kendo-actions--theme() {

    @include kendo-actions--theme-base();

    // Actions
    .k-actions {

        .k-button-solid-base {
            background-color: if($kendo-enable-color-system, k-color( base-subtle ), k-try-shade( $kendo-button-bg ));
            border-color: if($kendo-enable-color-system, k-color( base-subtle ), k-try-shade( $kendo-button-bg ));
        }

    }

}
