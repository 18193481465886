// Skeleton

/// The transform scale of the Skeleton text.
/// @group skeleton
$kendo-skeleton-text-transform: scale( 1, .6 ) !default;
/// The border radius of the Skeleton text.
/// @group skeleton
$kendo-skeleton-text-border-radius: k-border-radius(md) !default;

/// The border radius of the rectangular Skeleton.
/// @group skeleton
$kendo-skeleton-rect-border-radius: 0 !default;

/// The border radius of the circular Skeleton.
/// @group skeleton
$kendo-skeleton-circle-border-radius: 9999px !default;

/// The background color of the Skeleton item.
/// @group skeleton
$kendo-skeleton-item-bg: if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 20%, transparent), rgba( $kendo-color-inverse, .2 )) !default;
/// The background color of the Skeleton wave animation.
/// @group skeleton
$kendo-skeleton-wave-bg: rgba( black, .04 ) !default;
