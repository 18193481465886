// TabStrip


/// The horizontal padding of the TabStrip wrapper.
/// @group tabstrip
$kendo-tabstrip-wrapper-padding-x: k-spacing(0) !default;
/// The vertical padding of the TabStrip wrapper.
/// @group tabstrip
$kendo-tabstrip-wrapper-padding-y: k-spacing(0) !default;
/// The border width around the TabStrip wrapper.
/// @group tabstrip
$kendo-tabstrip-wrapper-border-width: 0px !default;

/// The font family of the TabStrip.
/// @group tabstrip
$kendo-tabstrip-font-family: var( --kendo-font-family, inherit ) !default;
/// The font size of the TabStrip.
/// @group tabstrip
$kendo-tabstrip-font-size: var( --kendo-font-size, inherit ) !default;
/// The line height of the TabStrip.
/// @group tabstrip
$kendo-tabstrip-line-height: k-math-div( 20, 14 ) !default;
/// The border width around the TabStrip.
/// @group tabstrip
$kendo-tabstrip-border-width: 1px !default;

/// The background color of the TabStrip wrapper.
/// @group tabstrip
$kendo-tabstrip-wrapper-bg: null !default;
/// The text color of the TabStrip wrapper.
/// @group tabstrip
$kendo-tabstrip-wrapper-text: null !default;
/// The border color of the TabStrip wrapper.
/// @group tabstrip
$kendo-tabstrip-wrapper-border: null !default;

/// The background color of the TabStrip.
/// @group tabstrip
$kendo-tabstrip-bg: null !default;
/// The text color of the TabStrip.
/// @group tabstrip
$kendo-tabstrip-text: $kendo-subtle-text !default;
/// The border color of the TabStrip.
/// @group tabstrip
$kendo-tabstrip-border: $kendo-component-border !default;

/// The horizontal padding of the TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-padding-x: k-spacing(6) !default;
/// The vertical padding of the TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-padding-y: k-spacing(3.5) !default;
/// The border width around the TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-border-width: 0px !default;
/// The border radius of the TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-border-radius: 0px !default;
/// The gap between the TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-gap: k-spacing(0) !default;

///  The background color of the TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-bg: null !default;
/// The text color of the TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-text: $kendo-subtle-text !default;
/// The border color of the TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-border: null !default;
/// The gradient of the TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-gradient: null !default;

/// The background color of the hovered TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-hover-bg: $kendo-hover-bg !default;
/// The text color of the hovered TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-hover-text: $kendo-body-text !default;
/// The border color of the hovered TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-hover-border: null !default;
/// The gradient of the hovered TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-hover-gradient: null !default;

/// The background color of the selected TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-selected-bg: null !default;
/// The text color of the selected TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-selected-text: $kendo-body-text !default;
/// The border color of the selected TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-selected-border: null !default;
/// The gradient of the selected TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-selected-gradient: null !default;

/// The shadow of the focused TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-focus-shadow: $kendo-list-item-focus-shadow !default;

/// The shadow of the dragged TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-dragging-shadow: $box-shadow-depth-3 !default;

/// The background color of the disabled TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-disabled-bg: null !default;
/// The text color of the disabled TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-disabled-text: if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 32%, transparent), k-map-get( $theme, disabled-text )) !default;
/// The border color of the disabled TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-disabled-border: null !default;
/// The gradient of the disabled TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-disabled-gradient: null !default;
/// The opacity of the disabled TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-disabled-opacity: 1 !default;
/// The filter of the disabled TabStrip items.
/// @group tabstrip
$kendo-tabstrip-item-disabled-filter: none !default;

/// The border width of the TabStrip indicator.
/// @group tabstrip
$kendo-tabstrip-indicator-size: 2px !default;
/// The border color of the TabStrip ripple.
/// @group tabstrip
$kendo-tabstrip-indicator-color: $kendo-color-primary !default;

/// The horizontal padding of the TabStrip content.
/// @group tabstrip
$kendo-tabstrip-content-padding-x: k-spacing(4) !default;
/// The vertical padding of the TabStrip content.
/// @group tabstrip
$kendo-tabstrip-content-padding-y: k-spacing(4) !default;
/// The border width around the TabStrip content.
/// @group tabstrip
$kendo-tabstrip-content-border-width: 0px !default;

/// The background color of the TabStrip content.
/// @group tabstrip
$kendo-tabstrip-content-bg: transparent !default;
/// The text color of the TabStrip content.
/// @group tabstrip
$kendo-tabstrip-content-text: null !default;
/// The border color of the TabStrip content.
/// @group tabstrip
$kendo-tabstrip-content-border: null !default;
/// The border color of the focused TabStrip content.
/// @group tabstrip
$kendo-tabstrip-content-focus-border: $kendo-component-text !default;
