@import "@progress/kendo-theme-default/scss/calendar/_layout.scss";


@mixin kendo-calendar--layout() {

    @include kendo-calendar--layout-base();

    // Calendar
    .k-calendar {

        .k-title,
        .k-calendar-title {
            font-weight: 500;
            text-transform: none;
        }
        .k-calendar-caption,
        .k-calendar-view th,
        .k-calendar-view .k-calendar-th,
        .k-meta-header,
        .k-month-header {
            font-weight: 500;
        }
        .k-calendar-view tbody th,
        .k-calendar-view .k-calendar-tbody .k-calendar-th {
            font-weight: 700;
        }
    }

}
