@import "../button/_variables.scss";


// Actions
$kendo-actions-margin-top: k-spacing(4) !default;
$kendo-actions-padding-x: k-spacing(2) !default;
$kendo-actions-padding-y: k-spacing(2) !default;
$kendo-actions-border-width: 0px !default;
$kendo-actions-button-spacing: k-spacing(2) !default;

$kendo-actions-bg: null !default;
$kendo-actions-text: null !default;
$kendo-actions-border: null !default;
$kendo-actions-gradient: null !default;
