@import "@progress/kendo-theme-default/scss/action-buttons/_layout.scss";


@mixin kendo-actions--layout() {

    @include kendo-actions--layout-base();

    // Actions
    .k-actions {

        // Button
        .k-button {
            @include box-shadow( none );
        }


        // Button group
        .k-button-group {
            @include box-shadow( none );
        }


        // Split button
        .k-split-button {
            @include box-shadow( none );
        }

    }

}
