@import "@progress/kendo-theme-default/scss/treeview/_theme.scss";


@mixin kendo-treeview--theme() {

    @include kendo-treeview--theme-base();

    // Treeview
    .k-treeview {}


    // Treeview item
    .k-treeview-item {}


    // Treeview leaf
    .k-treeview-leaf {

        // Hover state
        &:hover,
        &.k-hover {
            @include fill(
                $kendo-treeview-item-hover-text,
                $kendo-treeview-item-hover-bg,
                $kendo-treeview-item-hover-border,
                $kendo-treeview-item-hover-gradient
            );
        }

        // Focus state
        &:focus,
        &.k-focus {
            @include fill(
                $kendo-treeview-item-hover-text,
                $kendo-treeview-item-hover-bg,
                $kendo-treeview-item-hover-border,
                $kendo-treeview-item-hover-gradient
            );
        }

        // Selected state
        &.k-selected {
            @include fill(
                $kendo-treeview-item-selected-text,
                $kendo-treeview-item-selected-bg,
                $kendo-treeview-item-selected-border,
                $kendo-treeview-item-selected-gradient
            );
        }
    }

}
