// Chat

/// The horizontal padding of the Chat.
/// @group chat
$kendo-chat-padding-x: k-spacing(4) !default;
/// The vertical padding of the Chat.
/// @group chat
$kendo-chat-padding-y: k-spacing(4) !default;
/// The width of the Chat.
/// @group chat
$kendo-chat-width: 500px !default;
/// The height of the Chat.
/// @group chat
$kendo-chat-height: 600px !default;
/// The border width of the Chat.
/// @group chat
$kendo-chat-border-width: 1px !default;
/// The font family of the Chat.
/// @group chat
$kendo-chat-font-family: var( --kendo-font-family, inherit ) !default;
/// The font size of the Chat.
/// @group chat
$kendo-chat-font-size: var( --kendo-font-size, inherit )  !default;
/// The line height of the Chat.
/// @group chat
$kendo-chat-line-height: var( --kendo-line-height, normal ) !default;

/// The horizontal spacing between the items of the Chat.
/// @group chat
$kendo-chat-item-spacing-x: k-spacing(2) !default;
/// The vertical spacing between the items of the Chat.
/// @group chat
$kendo-chat-item-spacing-y: k-spacing(4) !default;

/// The horizontal padding of the Chat message list.
/// @group chat
$kendo-chat-message-list-padding-x: $kendo-chat-padding-x !default;
/// The vertical padding of the Chat message list.
/// @group chat
$kendo-chat-message-list-padding-y: $kendo-chat-padding-y !default;
/// The spacing of the Chat message list.
/// @group chat
$kendo-chat-message-list-spacing: $kendo-chat-item-spacing-y !default;

/// The font size of the Chat timestamp.
/// @group chat
$kendo-chat-timestamp-font-size: var( --kendo-font-size-sm, inherit ) !default;
/// The line height of the Chat timestamp.
/// @group chat
$kendo-chat-timestamp-line-height: var( --kendo-line-height-lg, normal ) !default;
/// The text transform of the Chat timestamp.
/// @group chat
$kendo-chat-timestamp-transform: uppercase !default;
/// The text color of the Chat timestamp.
/// @group chat
$kendo-chat-timestamp-text: $kendo-subtle-text !default;
/// The background color of the Chat timestamp.
/// @group chat
$kendo-chat-timestamp-bg: null !default;

/// The font size of the Chat message meta text.
/// @group chat
$kendo-chat-message-meta-font-size: var( --kendo-font-size-sm, inherit ) !default;
/// The line height of the Chat message meta text.
/// @group chat
$kendo-chat-message-meta-line-height: var( --kendo-line-height-lg, normal ) !default;

/// The font size of the Chat author text.
/// @group chat
$kendo-chat-author-font-size: var( --kendo-font-size-sm, inherit ) !default;
/// The line height of the Chat author text.
/// @group chat
$kendo-chat-author-line-height: var( --kendo-line-height-lg, normal ) !default;

/// The horizontal padding of the Chat bubble message.
/// @group chat
$kendo-chat-bubble-padding-x: k-spacing(3) !default;
/// The vertical padding of the Chat bubble message.
/// @group chat
$kendo-chat-bubble-padding-y: k-spacing(2) !default;
/// The spacing of the Chat bubble message.
/// @group chat
$kendo-chat-bubble-spacing: k-spacing(0.5) !default;
/// The line height of the Chat bubble message.
/// @group chat
$kendo-chat-bubble-line-height: k-math-div( 20, 14 ) !default;

/// The border radius of the Chat bubble message.
/// @group chat
$kendo-chat-bubble-border-radius: 12px !default;
/// The border radius of the Chat small bubble message
/// @group chat
$kendo-chat-bubble-border-radius-sm: 2px !default;

/// The size of the Chat Avatar.
/// @group chat
$kendo-chat-avatar-size: 32px !default;
/// The spacing of the Chat Avatar.
/// @group chat
$kendo-chat-avatar-spacing: $kendo-chat-item-spacing-x !default;

/// The horizontal padding of the Chat Toolbar.
/// @group chat
$kendo-chat-toolbar-padding-x: $kendo-toolbar-md-padding-x !default;
/// The vertical padding of the Chat Toolbar.
/// @group chat
$kendo-chat-toolbar-padding-y: $kendo-toolbar-md-padding-y !default;
/// The spacing of the Chat Toolbar.
/// @group chat
$kendo-chat-toolbar-spacing: $kendo-toolbar-md-spacing !default;
/// The background color of the Chat Toolbar.
/// @group chat
$kendo-chat-toolbar-bg: if($kendo-enable-color-system, k-color( surface ), k-try-shade( $kendo-button-bg, .5 )) !default;
/// The text color of the Chat Toolbar.
/// @group chat
$kendo-chat-toolbar-text: $kendo-toolbar-text !default;
/// The border color of the Chat Toolbar.
/// @group chat
$kendo-chat-toolbar-border: inherit !default;

/// The horizontal padding of the Chat quick reply.
/// @group chat
$kendo-chat-quick-reply-padding-x: k-spacing(3) !default;
/// The vertical padding of the Chat quick reply.
/// @group chat
$kendo-chat-quick-reply-padding-y: k-spacing(2) !default;
/// The spacing of the Chat quick reply.
/// @group chat
$kendo-chat-quick-reply-spacing: k-spacing(2) !default;
/// The line height of the Chat quick reply.
/// @group chat
$kendo-chat-quick-reply-line-height: $kendo-chat-bubble-line-height !default;


/// The background color of the Chat.
/// @group chat
$kendo-chat-bg: $kendo-app-bg !default;
/// The text color of the Chat.
/// @group chat
$kendo-chat-text: $kendo-component-text !default;
/// The border color of the Chat.
/// @group chat
$kendo-chat-border: $kendo-component-border !default;

/// The background color of the Chat bubble.
/// @group chat
$kendo-chat-bubble-bg: $kendo-component-bg !default;
/// The text color of the Chat bubble.
/// @group chat
$kendo-chat-bubble-text: $kendo-component-text !default;
/// The border color of the Chat bubble.
/// @group chat
$kendo-chat-bubble-border: $kendo-chat-bubble-bg !default;
/// The box shadow of the Chat bubble.
/// @group chat
$kendo-chat-bubble-shadow: $box-shadow-depth-1 !default;
/// The shadow of the hovered Chat bubble.
/// @group chat
$kendo-chat-bubble-hover-shadow: $box-shadow-depth-2 !default;
/// The shadow of the selected Chat bubble.
/// @group chat
$kendo-chat-bubble-selected-shadow: $box-shadow-depth-3 !default;

/// The background color of the Chat alt bubble.
/// @group chat
$kendo-chat-alt-bubble-bg: $kendo-color-primary !default;
/// The text color of the Chat alt bubble.
/// @group chat
$kendo-chat-alt-bubble-text: if($kendo-enable-color-system, k-color( on-primary ), k-contrast-color( $kendo-chat-alt-bubble-bg )) !default;
/// The border color of the Chat alt bubble.
/// @group chat
$kendo-chat-alt-bubble-border: $kendo-chat-alt-bubble-bg !default;
/// The shadow of the Chat alt bubble.
/// @group chat
$kendo-chat-alt-bubble-shadow: $box-shadow-depth-1 !default;
/// The shadow of the hovered Chat alt bubble.
/// @group chat
$kendo-chat-alt-bubble-hover-shadow: $box-shadow-depth-2 !default;
/// The shadow of the selected Chat alt bubble.
/// @group chat
$kendo-chat-alt-bubble-selected-shadow: $box-shadow-depth-3 !default;

/// The background color of the Chat quick reply.
/// @group chat
$kendo-chat-quick-reply-bg: transparent !default;
/// The text color of the Chat quick reply.
/// @group chat
$kendo-chat-quick-reply-text: $kendo-color-primary !default;
/// The border color of the Chat quick reply.
/// @group chat
$kendo-chat-quick-reply-border: $kendo-color-primary !default;

/// The background color of the hovered Chat quick reply.
/// @group chat
$kendo-chat-quick-reply-hover-bg: $kendo-color-primary !default;
/// The text color of the hovered Chat quick reply.
/// @group chat
$kendo-chat-quick-reply-hover-text: $kendo-color-primary-contrast !default;
/// The border color of the hovered Chat quick reply.
/// @group chat
$kendo-chat-quick-reply-hover-border: $kendo-color-primary !default;

/// The shadow blur of the focused Chat quick reply.
/// @group chat
$kendo-chat-quick-reply-shadow-blur: null !default;
/// The shadow spread of the focused Chat quick reply.
/// @group chat
$kendo-chat-quick-reply-shadow-spread: null !default;
/// The shadow opacity of the focused Chat quick reply.
/// @group chat
$kendo-chat-quick-reply-shadow-opacity: 0 !default;
