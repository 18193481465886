@import "@progress/kendo-theme-default/scss/calendar/_theme.scss";


@mixin kendo-calendar--theme() {

    @include kendo-calendar--theme-base();

    // Calendar
    .k-calendar {

        .k-focus .k-link {
            @include fill(
                $kendo-calendar-cell-hover-text,
                $kendo-calendar-cell-hover-bg,
                $kendo-calendar-cell-hover-border
            );
        }
        .k-selected.k-focus .k-link {
            @include fill(
                $kendo-calendar-cell-selected-text,
                if($kendo-enable-color-system, $kendo-calendar-cell-selected-bg, k-try-tint($kendo-calendar-cell-selected-bg)),
                $kendo-calendar-cell-selected-border
            );
        }

    }

}
