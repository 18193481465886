@import "@progress/kendo-theme-default/scss/listbox/_layout.scss";


@mixin kendo-listbox--layout() {

    @include kendo-listbox--layout-base();

    .k-listbox {
        .k-item {
            padding-block: $kendo-listbox-item-padding-y;
            padding-inline: $kendo-listbox-item-padding-x;
        }
    }

}
