// Action sheet

$kendo-actionsheet-padding-x: null !default;
$kendo-actionsheet-padding-y: null !default;
$kendo-actionsheet-width: 360px !default;
$kendo-actionsheet-max-width: 100% !default;
$kendo-actionsheet-height: 60vh !default;
$kendo-actionsheet-max-height: 60vh !default;

$kendo-actionsheet-border-width: 0px !default;
$kendo-actionsheet-border-radius: 0px !default;

$kendo-actionsheet-font-size: var( --kendo-font-size, inherit )  !default;
$kendo-actionsheet-font-family: var( --kendo-font-family, inherit ) !default;
$kendo-actionsheet-line-height: k-math-div( 20, 14 ) !default;

$kendo-actionsheet-bg: $kendo-component-bg !default;
$kendo-actionsheet-text: $kendo-component-text !default;
$kendo-actionsheet-border: $kendo-component-border !default;
$kendo-actionsheet-shadow: $box-shadow-depth-7 !default;


// Actionsheet header
$kendo-actionsheet-titlebar-padding-x: k-spacing(4) !default;
$kendo-actionsheet-titlebar-padding-y: k-spacing(2) !default;
$kendo-actionsheet-titlebar-border-width: null !default;
$kendo-actionsheet-titlebar-font-size: var( --kendo-font-size-lg, inherit ) !default;
$kendo-actionsheet-titlebar-font-family: null !default;
$kendo-actionsheet-titlebar-line-height: 1.25 !default;
$kendo-actionsheet-titlebar-gap: k-spacing(4) !default;

$kendo-actionsheet-titlebar-bg: null !default;
$kendo-actionsheet-titlebar-text: null !default;
$kendo-actionsheet-titlebar-border: null !default;
$kendo-actionsheet-titlebar-gradient: null !default;
$kendo-actionsheet-titlebar-shadow: null !default;

$kendo-actionsheet-subtitle-font-size: var( --kendo-font-size-sm, inherit ) !default;
$kendo-actionsheet-subtitle-line-height: var( --kendo-line-height-sm, normal ) !default;
$kendo-actionsheet-subtitle-text: $kendo-subtle-text !default;


// Actionsheet item
$kendo-actionsheet-item-min-height: 40px !default;
$kendo-actionsheet-item-padding-x: k-spacing(4) !default;
$kendo-actionsheet-item-padding-y: k-spacing(2) !default;
$kendo-actionsheet-item-border-width: 1px !default;
$kendo-actionsheet-item-spacing: k-spacing(3) !default;

$kendo-actionsheet-item-title-font-weight: null !default;
$kendo-actionsheet-item-title-text-transform: null !default;

$kendo-actionsheet-item-description-font-size: .875em !default;
$kendo-actionsheet-item-description-text: $kendo-subtle-text !default;

$kendo-actionsheet-item-hover-bg: rgba( black, .04 ) !default;
$kendo-actionsheet-item-hover-text: null !default;
$kendo-actionsheet-item-hover-border: null !default;
$kendo-actionsheet-item-hover-gradient: null !default;
$kendo-actionsheet-item-hover-shadow: null !default;

$kendo-actionsheet-item-focus-bg: rgba( black, .12 ) !default;
$kendo-actionsheet-item-focus-text: null !default;
$kendo-actionsheet-item-focus-border: null !default;
$kendo-actionsheet-item-focus-gradient: null !default;
$kendo-actionsheet-item-focus-shadow: null !default;

$kendo-actionsheet-item-disabled-bg: null !default;
$kendo-actionsheet-item-disabled-text: null !default;
$kendo-actionsheet-item-disabled-border: null !default;
$kendo-actionsheet-item-disabled-gradient: null !default;
$kendo-actionsheet-item-disabled-shadow: null !default;

$kendo-actionsheet-item-hover-background: rgba( black, .04 ) !default;
$kendo-actionsheet-item-focus-background: rgba( black, .12 ) !default;
$kendo-actionsheet-item-focus-shadow: null !default;
$kendo-actionsheet-item-disabled-opacity: .42 !default;


// Adaptive Actionsheet
$kendo-adaptive-actionsheet-font-size: var( --kendo-font-size-lg, inherit ) !default;
$kendo-adaptive-actionsheet-titlebar-border-width: 1px !default;
$kendo-adaptive-actionsheet-titlebar-padding-y: k-spacing(4) !default;
$kendo-adaptive-actionsheet-titlebar-padding-x: $kendo-adaptive-actionsheet-titlebar-padding-y !default;
$kendo-adaptive-actionsheet-titlebar-border: $kendo-component-border !default;

$kendo-adaptive-actionsheet-content-padding-y: k-spacing(2) !default;
$kendo-adaptive-actionsheet-content-padding-x: k-spacing(4) !default;

$kendo-adaptive-actionsheet-footer-padding-y: k-spacing(2) !default;
$kendo-adaptive-actionsheet-footer-padding-x: k-spacing(4) !default;
