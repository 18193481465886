@mixin kendo-panel--layout--base() {
        // Panels
        .k-block,
        .k-panel {
            @include border-radius( $kendo-panel-border-radius );
            padding: 0;
            border-width: $kendo-panel-border-width;
            border-style: $kendo-panel-border-style;
            box-sizing: border-box;

            > .k-header {
                @include border-top-radius( $kendo-panel-border-radius );
                @include fill(
                    var( --kendo-component-text, #{$kendo-component-text} ),
                    var( --kendo-component-bg, #{$kendo-component-bg} ),
                    var( --kendo-component-border, #{$kendo-component-border} )
                );
                padding-inline: $kendo-panel-header-padding-inline;
                padding-block: $kendo-panel-header-padding-block;
            }
            > .k-content {
                padding-inline: $kendo-panel-content-padding-inline;
                padding-block: $kendo-panel-content-padding-block;
            }
        }
}

@mixin kendo-panel--layout() {
    @include kendo-panel--layout--base();
}
