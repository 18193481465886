@import "@progress/kendo-theme-default/scss/menu/_layout.scss";


@mixin kendo-menu--layout() {

    @include kendo-menu--layout-base();

    // Menubar
    .k-menu:not(.k-context-menu) {

        // Root items
        > .k-menu-item {
            transition: $kendo-transition;
        }
    }

    // Scrolling
    .k-menu-scroll-wrapper {

        .k-menu-scroll-button {
            box-shadow: none;
        }

        &.horizontal .k-menu-scroll-button {
            width: calc( #{$kendo-line-height-em} + #{k-spacing(1)});
        }

        &.vertical .k-menu-scroll-button {
            height: calc( #{$kendo-line-height-em} + #{k-spacing(1)});
        }
    }

}
