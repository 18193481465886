// Time selector

$kendo-time-selector-border-width: 1px !default;
$kendo-time-selector-font-family: var( --kendo-font-family, inherit ) !default;
$kendo-time-selector-font-size: var( --kendo-font-size, inherit ) !default;
$kendo-time-selector-line-height: k-math-div( 20, 14 ) !default;

$kendo-time-selector-bg: $kendo-component-bg !default;
$kendo-time-selector-text: $kendo-component-text !default;
$kendo-time-selector-border: $kendo-component-border !default;

$kendo-time-selector-header-padding-x: $kendo-actions-padding-x !default;
$kendo-time-selector-header-padding-y: $kendo-actions-padding-y !default;
$kendo-time-selector-header-border-width: 0px !default;

$kendo-time-selector-fast-selection-gap: k-spacing(2) !default;

$kendo-time-list-width: 4em !default;
$kendo-time-list-height: 240px !default;

$kendo-time-list-title-font-size: var( --kendo-font-size-sm, inherit ) !default;
$kendo-time-list-title-line-height: var( --kendo-line-height-lg, normal ) !default;
$kendo-time-list-title-height: calc( #{$kendo-time-list-title-font-size} * #{$kendo-time-list-title-line-height} ) !default;
$kendo-time-list-title-text: $kendo-subtle-text !default;
$kendo-time-list-title-focus-text: $kendo-component-text !default;

$kendo-time-list-item-padding-x: $kendo-list-md-item-padding-x !default;
$kendo-time-list-item-padding-y: $kendo-list-md-item-padding-y !default;

$kendo-time-list-highlight-border-width: 1px 0px !default;
$kendo-time-list-highlight-height: calc( calc( #{$kendo-time-selector-font-size} * #{$kendo-time-selector-line-height} ) + calc( #{$kendo-time-list-item-padding-y} * 2 ) ) !default;
$kendo-time-list-highlight-bg: $kendo-component-bg !default;
$kendo-time-list-highlight-border: $kendo-component-border !default;

$kendo-time-list-focus-bg: null !default;


// Time selector sizes
$kendo-time-selector-sm-font-size: $kendo-list-sm-font-size !default;
$kendo-time-selector-sm-line-height: $kendo-list-sm-line-height !default;
$kendo-time-selector-sm-list-item-padding-x: $kendo-list-sm-item-padding-x !default;
$kendo-time-selector-sm-list-item-padding-y: $kendo-list-sm-item-padding-y !default;

$kendo-time-selector-md-font-size: $kendo-list-md-font-size !default;
$kendo-time-selector-md-line-height: $kendo-list-md-line-height !default;
$kendo-time-selector-md-list-item-padding-x: $kendo-list-md-item-padding-x !default;
$kendo-time-selector-md-list-item-padding-y: $kendo-list-md-item-padding-y !default;

$kendo-time-selector-lg-font-size: $kendo-list-lg-font-size !default;
$kendo-time-selector-lg-line-height: $kendo-list-lg-line-height !default;
$kendo-time-selector-lg-list-item-padding-x: $kendo-list-lg-item-padding-x !default;
$kendo-time-selector-lg-list-item-padding-y: $kendo-list-lg-item-padding-y !default;

$kendo-time-selector-sizes: (
    sm: (
        font-size: $kendo-time-selector-sm-font-size,
        line-height: $kendo-time-selector-sm-line-height,
        list-item-padding-x: $kendo-time-selector-sm-list-item-padding-x,
        list-item-padding-y: $kendo-time-selector-sm-list-item-padding-y
    ),
    md: (
        font-size: $kendo-time-selector-md-font-size,
        line-height: $kendo-time-selector-md-line-height,
        list-item-padding-x: $kendo-time-selector-md-list-item-padding-x,
        list-item-padding-y: $kendo-time-selector-md-list-item-padding-y
    ),
    lg: (
        font-size: $kendo-time-selector-lg-font-size,
        line-height: $kendo-time-selector-lg-line-height,
        list-item-padding-x: $kendo-time-selector-lg-list-item-padding-x,
        list-item-padding-y: $kendo-time-selector-lg-list-item-padding-y
    )
) !default;
