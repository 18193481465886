@import "@progress/kendo-theme-default/scss/fab/_layout.scss";


@mixin kendo-fab--layout() {

    @include kendo-fab--layout-base();

    .k-fab-text {
        text-transform: uppercase;
    }

}
