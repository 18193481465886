@mixin kendo-core--styles--base() {

    // Disabled state
    .k-disabled,
    .k-widget[disabled],
    .k-disabled {
        @include disabled(
            $color: var( --kendo-disabled-text, #{$kendo-disabled-text}),
            $bg: var( --kendo-disabled-bg, #{$kendo-disabled-bg}),
            $border: var( --kendo-disabled-border, #{$kendo-disabled-border}),
            $opacity: var( --kendo-disabled-opacity, #{$kendo-disabled-opacity}),
            $filter: var( --kendo-disabled-filter, #{$kendo-disabled-filter})
        );

        .k-link {
            cursor: default;
            outline: 0;
        }

        // Nested disabled
        [disabled],
        .k-disabled {
            @if $kendo-nested-disabled {
                --kendo-disabled-opacity: 1;
                --kendo-disabled-filter: grayscale(0);
            }
        }
    }

    // Horizontal line
    .k-hr {
        margin-block: k-spacing(4);
        padding: 0;
        height: 0;
        border-width: 1px 0 0;
        border-style: solid;
        border-color: var( --kendo-component-border, #{$kendo-component-border} );
        display: block;
        float: none;
        clear: both;
    }

    // Horizontal rule
    .k-d-flex-row > .k-hr {
        margin: 0;
        width: 0;
        height: auto;
        border-width: 0 0 0 1px;
        flex: 0 0 auto;
    }

    // Vertical rule
    .k-d-flex-col > .k-hr {
        margin: 0;
        flex: 0 0 auto;
    }

    .k-sprite {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        overflow: hidden;
        background-repeat: no-repeat;
        font-size: 0;
        line-height: 0;
        text-align: center;
    }

    .k-image {
        display: inline-block;
    }

    // Layout
    .k-reset {
        margin: 0;
        padding: 0;
        border-width: 0;
        outline: 0;
        text-decoration: none;
        font: inherit;
        list-style: none;
    }

    kendo-sortable {
        display: block;
    }


    // Links
    .k-link,
    .k-link:hover {
        color: inherit;
        text-decoration: none;
        outline: 0;
        cursor: pointer;
    }

    // Outline
    .k-content {
        outline: 0;
    }

    // Centering
    .k-centered {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    // Disable mouse events
    .k-no-click {
        pointer-events: none;
    }

    // Off-screen container used during PDF export
    .k-pdf-export-shadow {
        position: absolute;
        overflow: hidden;
        left: -15000px;
        width: 14400px;
    }

    // PDF export icons fix
    .kendo-pdf-hide-pseudo-elements::before,
    .kendo-pdf-hide-pseudo-elements::after {
        display: none !important; // stylelint-disable-line declaration-no-important
    }

    // Dirty indicator
    .k-dirty {
        margin: 0;
        padding: 0;
        width: 0;
        height: 0;
        border-width: 3px;
        border-style: solid;
        border-block-start-color: currentColor;
        border-block-end-color: transparent;
        border-inline-start-color: transparent;
        border-inline-end-color: currentColor;
        position: absolute;
        inset-block-start: 0;
        inset-inline-end: 0;
    }
}
