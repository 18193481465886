@import "@progress/kendo-theme-default/scss/listview/_theme.scss";


@mixin kendo-listview--theme() {

    @include kendo-listview--theme-base();

    // Listview
    .k-listview {}


    // Listview content
    .k-listview-content {}
    .k-listview-content > .k-selected.k-focus {
        background-color: if($kendo-enable-color-system, k-color( base-active ), rgba( k-contrast-color( $kendo-listview-bg ), .12 ));
    }

}
