// Avatar

/// The border width of the Avatar.
/// @group avatar
$kendo-avatar-border-width: 1px !default;

/// The font family of the Avatar.
/// @group avatar
$kendo-avatar-font-family: var( --kendo-font-family, inherit ) !default;
/// The font size of the Avatar.
/// @group avatar
$kendo-avatar-font-size: var( --kendo-font-size, inherit )  !default;
/// The line height of the Avatar.
/// @group avatar
$kendo-avatar-line-height: var( --kendo-line-height, normal ) !default;

/// The sizes map of the Avatar.
/// @group avatar
$kendo-avatar-sizes: (
    sm: k-spacing(4),
    md: k-spacing(8),
    lg: k-spacing(16)
) !default;

/// The theme colors map of the Avatar.
/// @group avatar
$kendo-avatar-theme-colors: $kendo-theme-colors !default;
