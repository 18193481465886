@use "@angular/material" as mat;
@use "sass:map";
@use "../abstracts/app-palette" as appPalette;
@use "../../app/pages/ai-assist/ai-assist.component.theme.scss" as chat;

/*
 Extra color schema:
 Add any custom colors here.
 Some are based on Bootstrap (success, warning, danger, info)
 */
$app-success: mat.m2-define-palette(mat.$m2-green-palette);
$app-info: mat.m2-define-palette(mat.$m2-blue-palette);
$app-warning: mat.m2-define-palette(mat.$m2-yellow-palette);
$app-error: mat.m2-define-palette(mat.$m2-red-palette);
$app-ice: mat.m2-define-palette(appPalette.$cgi-ice-palette);
$app-beet: mat.m2-define-palette(appPalette.$cgi-beet-palette);

$app-typography: mat.m2-define-typography-config(
  $font-family: "'Source Sans Pro', 'Helvetica Neue', Arial, Verdana, sans-serif",
  //$headline: mat.m2-define-typography-level(40px, 1.15),
  //$title: mat.m2-define-typography-level(28px, 1.4, 600),
  //$subheading-2: mat.m2-define-typography-level(20px, 1.15, 700),
  //$subheading-1: mat.m2-define-typography-level(18px, 1.4, 700),
  //$body-1: mat.m2-define-typography-level(16px, 1.6),
  //$caption: mat.m2-define-typography-level(14px, 1.6)
);

/*
This function adds extras to the theme.
Example: you can add extra colors
 */
@function apply-extra-colors($theme) {
  @return map.deep-merge(
      $theme,
      (
        color: (
          success: $app-success,
          info: $app-info,
          warning: $app-warning,
          error: $app-error,
          ice: $app-ice,
          beet: $app-beet
        ),
        // Add foreground colors here
        //foreground: (
        //  text: blue,
        //  secondary-text: yellow
        //),
        // Add background here
        background: (
          background: map.get(mat.$m2-gray-palette, 100)
        )
      )
  );
}

@mixin create-theme($app-theme) {
  /*
   NOTE:  We may want to generate themes per component if we do not use many in the application.  This will reduce
   bundle size.
   */
   @include mat.all-component-themes($app-theme);
  //  @include chat.ai-assit-theme($app-theme);

  // APPLY CUSTOM COMPONENT/MODULE THEMES HERE
}
