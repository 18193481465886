@import "@progress/kendo-theme-default/scss/dialog/_theme.scss";


@mixin kendo-dialog--theme() {

    @include kendo-dialog--theme-base();

    // Dialog
    .k-dialog {}


    // Titlebar
    .k-dialog-titlebar {
        @include fill(
            $kendo-dialog-titlebar-text,
            $kendo-dialog-titlebar-bg,
            $kendo-dialog-titlebar-border
        );
    }

    // Content scroll border
    .k-dialog-content.k-scroll {
        border-color: $kendo-component-border;
    }

}
