// List

/// The font family of the List components.
/// @group list
$kendo-list-font-family: var( --kendo-font-family, inherit ) !default;

/// The font size of the List component, if no size is set.
/// @group list
$kendo-list-font-size: null !default;
$kendo-list-sm-font-size: var( --kendo-font-size, inherit )!default;
$kendo-list-md-font-size: var( --kendo-font-size, inherit ) !default;
$kendo-list-lg-font-size: var( --kendo-font-size-lg, inherit ) !default;

/// The line height of the List component, if no size is set.
/// @group list
$kendo-list-line-height: null !default;
$kendo-list-sm-line-height: k-math-div( 20, 14 ) !default;
$kendo-list-md-line-height: k-math-div( 20, 14 ) !default;
$kendo-list-lg-line-height: 1.5 !default;

/// The horizontal padding of the List header, if no size is set.
/// @group list
$kendo-list-header-padding-x: null !default;
$kendo-list-sm-header-padding-x: k-spacing(4) !default;
$kendo-list-md-header-padding-x: k-spacing(4) !default;
$kendo-list-lg-header-padding-x: k-spacing(4) !default;

/// The vertical padding of the List header, if no size is set.
/// @group list
$kendo-list-header-padding-y: null !default;
$kendo-list-sm-header-padding-y: k-spacing(1.5) !default;
$kendo-list-md-header-padding-y: k-spacing(2) !default;
$kendo-list-lg-header-padding-y: k-spacing(2) !default;

/// The border width of the List header.
/// @group list
$kendo-list-header-border-width: 0 0 1px !default;

/// The font size of the List header, if no size is set.
/// @group list
$kendo-list-header-font-size: null !default;
$kendo-list-sm-header-font-size: null !default;
$kendo-list-md-header-font-size: null !default;
$kendo-list-lg-header-font-size: null !default;

/// The line height of the List header, if no size is set.
/// @group list
$kendo-list-header-line-height: null !default;
$kendo-list-sm-header-line-height: null !default;
$kendo-list-md-header-line-height: null !default;
$kendo-list-lg-header-line-height: null !default;

/// The font weight of the List header.
/// @group list
$kendo-list-header-font-weight: var( --kendo-font-weight-bold, normal ) !default;

/// The horizontal padding of the List items, when no size is set.
/// @group list
$kendo-list-item-padding-x: null !default;
$kendo-list-sm-item-padding-x: k-spacing(4) !default;
$kendo-list-md-item-padding-x: k-spacing(4) !default;
$kendo-list-lg-item-padding-x: k-spacing(4) !default;

/// The vertical padding of the List items, when no size is set.
/// @group list
$kendo-list-item-padding-y: null !default;
$kendo-list-sm-item-padding-y: k-spacing(1.5) !default;
$kendo-list-md-item-padding-y: k-spacing(2) !default;
$kendo-list-lg-item-padding-y: k-spacing(2) !default;

/// The font size of the List items, if no size is set.
/// @group list
$kendo-list-item-font-size: null !default;
$kendo-list-sm-item-font-size: null !default;
$kendo-list-md-item-font-size: null !default;
$kendo-list-lg-item-font-size: null !default;

/// The line height of the List items, if no size is set.
/// @group list
$kendo-list-item-line-height: null !default;
$kendo-list-sm-item-line-height: null !default;
$kendo-list-md-item-line-height: null !default;
$kendo-list-lg-item-line-height: null !default;

/// The horizontal padding of the List group items, when no size is set.
/// @group list
$kendo-list-group-item-padding-x: null !default;
$kendo-list-sm-group-item-padding-x: k-spacing(4) !default;
$kendo-list-md-group-item-padding-x: k-spacing(4) !default;
$kendo-list-lg-group-item-padding-x: k-spacing(4) !default;

/// The vertical padding of the List group items, when no size is set.
/// @group list
$kendo-list-group-item-padding-y: null !default;
$kendo-list-sm-group-item-padding-y: k-spacing(1.5) !default;
$kendo-list-md-group-item-padding-y: k-spacing(2) !default;
$kendo-list-lg-group-item-padding-y: k-spacing(2.5) !default;

/// The border width of the List group items.
/// @group list
$kendo-list-group-item-border-width: 1px 0 0 !default;

/// The font size of the List group items, if no size is set.
/// @group list
$kendo-list-group-item-font-size: null !default;
$kendo-list-sm-group-item-font-size: null !default;
$kendo-list-md-group-item-font-size: null !default;
$kendo-list-lg-group-item-font-size: null !default;

/// The line height of the List group items, if no size is set.
/// @group list
$kendo-list-group-item-line-height: null !default;
$kendo-list-sm-group-item-line-height: null !default;
$kendo-list-md-group-item-line-height: null !default;
$kendo-list-lg-group-item-line-height: null !default;

/// The font weight of a List group item.
/// @group list
$kendo-list-group-item-font-weight: var( --kendo-font-weight-bold, normal ) !default;


/// The map with the sizes of the List.
/// /// @group list
$kendo-list-sizes: (
    sm: (
        font-size: $kendo-list-sm-font-size,
        line-height: $kendo-list-sm-line-height,
        header-padding-x: $kendo-list-sm-header-padding-x,
        header-padding-y: $kendo-list-sm-header-padding-y,
        header-font-size: null,
        header-line-height: null,
        item-padding-x: $kendo-list-sm-item-padding-x,
        item-padding-y: $kendo-list-sm-item-padding-y,
        item-font-size: null,
        item-line-height: null,
        group-item-padding-x: $kendo-list-sm-group-item-padding-x,
        group-item-padding-y: $kendo-list-sm-group-item-padding-y,
        group-item-font-size: null,
        group-item-line-height: null
    ),
    md: (
        font-size: $kendo-list-md-font-size,
        line-height: $kendo-list-md-line-height,
        header-padding-x: $kendo-list-md-header-padding-x,
        header-padding-y: $kendo-list-md-header-padding-y,
        header-font-size: null,
        header-line-height: null,
        item-padding-x: $kendo-list-md-item-padding-x,
        item-padding-y: $kendo-list-md-item-padding-y,
        item-font-size: null,
        item-line-height: null,
        group-item-padding-x: $kendo-list-md-group-item-padding-x,
        group-item-padding-y: $kendo-list-md-group-item-padding-y,
        group-item-font-size: null,
        group-item-line-height: null
    ),
    lg: (
        font-size: $kendo-list-lg-font-size,
        line-height: $kendo-list-lg-line-height,
        header-padding-x: $kendo-list-lg-header-padding-x,
        header-padding-y: $kendo-list-lg-header-padding-y,
        header-font-size: null,
        header-line-height: null,
        item-padding-x: $kendo-list-lg-item-padding-x,
        item-padding-y: $kendo-list-lg-item-padding-y,
        item-font-size: null,
        item-line-height: null,
        group-item-padding-x: $kendo-list-lg-group-item-padding-x,
        group-item-padding-y: $kendo-list-lg-group-item-padding-y,
        group-item-font-size: null,
        group-item-line-height: null
    )
) !default;


/// The background color of the List component.
/// @group list
$kendo-list-bg: $kendo-component-bg !default;
/// The text color of the List component.
/// @group list
$kendo-list-text: $kendo-component-text !default;
/// The border color of the List component.
/// @group list
$kendo-list-border: $kendo-component-border !default;


/// The background color of the List header.
/// @group list
$kendo-list-header-bg: null !default;
/// The text color of the List header.
/// @group list
$kendo-list-header-text: null !default;
/// The border color of the List header.
/// @group list
$kendo-list-header-border: inherit !default;
/// The box shadow of the List header.
/// @group list
$kendo-list-header-shadow: null !default;


/// The background color of the List items.
/// @group list
$kendo-list-item-bg: null !default;
/// The text color of the List items.
/// @group list
$kendo-list-item-text: null !default;

/// The background color of the hovered List items.
/// @group list
$kendo-list-item-hover-bg: $kendo-hover-bg !default;
/// The text color of the hovered List items.
/// @group list
$kendo-list-item-hover-text: $kendo-hover-text !default;

/// The background color of the focused List items.
/// @group list
$kendo-list-item-focus-bg: $kendo-list-item-hover-bg !default;
/// The text color of the focused List items.
/// @group list
$kendo-list-item-focus-text: null !default;
/// The box shadow of the focused List items.
/// @group list
$kendo-list-item-focus-shadow: null !default;

/// The background color of the selected List items.
/// @group list
$kendo-list-item-selected-bg: $kendo-component-bg !default;
/// The text color of the selected List items.
/// @group list
$kendo-list-item-selected-text: $kendo-color-secondary !default;

/// The background color of the List group items.
/// @group list
$kendo-list-group-item-bg: null !default;
/// The text color of the List group items.
/// @group list
$kendo-list-group-item-text: null !default;
/// The border color of the List group items.
/// @group list
$kendo-list-group-item-border: inherit !default;
/// The base shadow of the List group items.
/// @group list
$kendo-list-group-item-shadow: null !default;

/// The color of the 'No Data' text.
/// @group list
$kendo-list-no-data-text: $kendo-subtle-text !default;

/// The color of the 'Option Label' text.
/// @group list
$kendo-list-option-label-text: $kendo-subtle-text !default;
