@import "@progress/kendo-theme-default/scss/tabstrip/_layout.scss";


@mixin kendo-tabstrip--layout() {

    @include kendo-tabstrip--layout-base();

    .k-tabstrip-items {
        text-transform: uppercase;
    }

    .k-tabstrip-left .k-tabstrip-items .k-link {
        justify-content: flex-end;
    }

}
