@import "@progress/kendo-theme-default/scss/drawer/_theme.scss";


@mixin kendo-drawer--theme() {

    @include kendo-drawer--theme-base();

    .k-drawer-item {
        &.k-selected {

            &:focus,
            &.k-focus {
                background-color: $kendo-drawer-focus-bg;
                box-shadow: $kendo-drawer-focus-shadow;
            }
        }
    }

}
