// TileLayout

/// The width of the border around the TileLayout.
/// @group tile-layout
$kendo-tile-layout-border-width: 0px !default;
/// The background color of the TileLayout.
/// @group tile-layout
$kendo-tile-layout-bg: if($kendo-enable-color-system, k-color( surface ), if( $kendo-is-dark-theme, $kendo-color-dark, $kendo-color-light)) !default;

/// The horizontal padding of the TileLayout.
/// @group tile-layout
$kendo-tile-layout-padding-x: k-spacing(4) !default;

/// The vertical padding of the TileLayout
/// @group tile-layout
$kendo-tile-layout-padding-y: $kendo-tile-layout-padding-x !default;

/// The width of the border around the TileLayout card.
/// @group tile-layout
$kendo-tile-layout-card-border-width: 1px !default;
/// The focus box shadow of the TileLayout card.
/// @group tile-layout
$kendo-tile-layout-card-focus-shadow: $kendo-card-focus-shadow !default;

/// The width of the border around the TileLayout hint.
/// @group tile-layout
$kendo-tile-layout-hint-border-width: 1px !default;
/// The radius of the border around the TileLayout hint.
/// @group tile-layout
$kendo-tile-layout-hint-border-radius: k-border-radius(md) !default;
/// The color of the border around the TileLayout hint.
/// @group tile-layout
$kendo-tile-layout-hint-border: $kendo-component-border !default;
/// The background color of the TileLayout hint.
/// @group tile-layout
$kendo-tile-layout-hint-bg: rgba( white, .2 ) !default;

