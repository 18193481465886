@import "@progress/kendo-theme-default/scss/switch/_theme.scss";


@mixin kendo-switch--theme() {

    @include kendo-switch--theme-base();

    // Switch
    .k-switch {

        .k-switch-thumb {
            box-shadow: $box-shadow-depth-2;
        }

        // Thumb ripple
        &.k-switch-on .k-switch-thumb::before {
            background-color: inherit;
        }
        &.k-switch-off .k-switch-thumb::before {
            background-color: if($kendo-enable-color-system, k-color( on-app-surface ), if( $kendo-is-dark-theme, $kendo-color-white, $kendo-color-black ));
        }
    }

}
