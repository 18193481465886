@import "@progress/kendo-theme-default/scss/gantt/_layout.scss";


@mixin kendo-gantt--layout() {

    @include kendo-gantt--layout-base();

    // Timeline
    .k-gantt-timeline-pane {
        .k-rtl & {

            .k-header {
                border-width: 0 $kendo-grid-cell-vertical-border-width 1px 0;
            }
        }
    }

}
