@import "@progress/kendo-theme-default/scss/slider/_layout.scss";


@mixin kendo-slider--layout() {

    @include kendo-slider--layout-base();

    .k-slider {
        .k-tick {
            margin: 0;

            &::after {
                $tick-offset: - k-math-div( $kendo-slider-tick-size, 2 );

                content: "";
                position: absolute;
                z-index: 1;
                height: $kendo-slider-tick-size;
                width: $kendo-slider-tick-size;
                top: 50%;
                left: 50%;
                margin: $tick-offset 0 0 $tick-offset;
            }
        }

        .k-draghandle {
            z-index: 2;
        }
    }

    .k-slider-horizontal {
        .k-tick {
            &.k-first::after {
                left: 0;
            }
            &.k-last::after {
                left: auto;
                right: 0;
            }
        }
    }

    .k-slider-vertical {
        .k-tick {
            &.k-last::after {
                top: 0;
            }
            &.k-first::after {
                top: auto;
                bottom: 0;
            }
        }
    }

}
