@import "@progress/kendo-theme-default/scss/floating-label/_layout.scss";


@mixin kendo-floating-label--layout() {

    @include kendo-floating-label--layout-base();

    // Floating label
    .k-floating-label-container {

        > .k-floating-label {
            transform-origin: left bottom;
        }

        [dir="rtl"] &,
        .k-rtl &,
        &[dir="rtl"] {

            > .k-floating-label {
                transform-origin: right bottom;
            }

        }
    }

}
