@import "@progress/kendo-theme-default/scss/table/_layout.scss";


@mixin kendo-table--layout() {
    @include kendo-table--layout-base();

    @if($kendo-enable-color-system) {
        .k-table {
            td:focus,
            td.k-focus,
            th:focus,
            th.k-focus {
                position: relative;

                &::after {
                    content: "";
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    pointer-events: none;
                }
            }

            td.k-grid-content-sticky,
            td.k-grid-header-sticky {
                position: sticky;
            }
        }
    }
}
