// ColorEditor/FlatColorPicker

/// The spacer of the ColorEditor.
/// @group color-editor
$kendo-color-editor-spacer: k-spacing(3) !default;

/// The minimum width of the ColorEditor.
/// @group color-editor
$kendo-color-editor-min-width: 294px !default;
/// The width of the border around the ColorEditor.
/// @group color-editor
$kendo-color-editor-border-width: 1px !default;
/// The border radius of the ColorEditor.
/// @group color-editor
$kendo-color-editor-border-radius: k-border-radius(md) !default;
/// The font family of the ColorEditor.
/// @group color-editor
$kendo-color-editor-font-family: var( --kendo-font-family, inherit ) !default;
/// The font size of the ColorEditor.
/// @group color-editor
$kendo-color-editor-font-size: var( --kendo-font-size, inherit )  !default;
/// The line height of the ColorEditor.
/// @group color-editor
$kendo-color-editor-line-height: var( --kendo-line-height, normal ) !default;
/// The text color of the ColorEditor.
/// @group color-editor
$kendo-color-editor-text: $kendo-component-text !default;
/// The background color of the ColorEditor.
/// @group color-editor
$kendo-color-editor-bg: $kendo-component-bg !default;
/// The border color of the ColorEditor.
/// @group color-editor
$kendo-color-editor-border: $kendo-component-border !default;

/// The border color of the focused ColorEditor.
/// @group color-editor
$kendo-color-editor-focus-border: $kendo-hover-border !default;
/// The box shadow of the focused ColorEditor.
/// @group color-editor
$kendo-color-editor-focus-shadow: $box-shadow-depth-3 !default;

/// The vertical padding of the ColorEditor header.
/// @group color-editor
$kendo-color-editor-header-padding-y: $kendo-color-editor-spacer !default;
/// The horizontal padding of the ColorEditor header.
/// @group color-editor
$kendo-color-editor-header-padding-x: $kendo-color-editor-header-padding-y !default;
/// The spacing between the ColorEditor header actions.
/// @group color-editor
$kendo-color-editor-header-actions-gap: k-spacing(2) !default;

/// The width of the ColorEditor preview.
/// @group color-editor
$kendo-color-editor-color-preview-width: 32px !default;
/// The height of the ColorEditor preview.
/// @group color-editor
$kendo-color-editor-color-preview-height: 12px !default;
/// The spacing between the colors in the ColorEditor preview.
/// @group color-editor
$kendo-color-editor-preview-gap: k-spacing(1) !default;

/// The vertical padding of the ColorEditor views container.
/// @group color-editor
$kendo-color-editor-views-padding-y: $kendo-color-editor-spacer !default;
/// The horizontal padding of the ColorEditor views container.
/// @group color-editor
$kendo-color-editor-views-padding-x: $kendo-color-editor-views-padding-y !default;
/// The spacing of the ColorEditor views container.
/// @group color-editor
$kendo-color-editor-views-gap: $kendo-color-editor-spacer !default;

/// The outline color of the focused ColorGradient.
/// @group color-editor
$kendo-color-editor-color-gradient-focus-outline-color: rgba(0, 0, 0, .3) !default;
/// The outline width of the focused ColorGradient.
/// @group color-editor
$kendo-color-editor-color-gradient-focus-outline: 2px !default;
/// The outline offset of the focused ColorGradient.
/// @group color-editor
$kendo-color-editor-color-gradient-focus-outline-offset: 2px !default;
