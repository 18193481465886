// Tooltip

/// The vertical padding of the Tooltip.
/// @group tooltip
$kendo-tooltip-padding-y: calc( #{$kendo-padding-md-y} * .75 ) !default;
/// The horizontal padding of the Tooltip.
/// @group tooltip
$kendo-tooltip-padding-x: calc( #{$kendo-padding-md-x} / 2 ) !default;
/// The width of the border around the Tooltip.
/// @group tooltip
$kendo-tooltip-border-width: 0px !default;
/// The border radius of the Tooltip.
/// @group tooltip
$kendo-tooltip-border-radius: k-border-radius(md) !default;

/// The font family of the Tooltip.
/// @group tooltip
$kendo-tooltip-font-family: var( --kendo-font-family, inherit ) !default;
/// The font size of the Tooltip.
/// @group tooltip
$kendo-tooltip-font-size: var( --kendo-font-size-sm, inherit ) !default;
/// The line height of the Tooltip.
/// @group tooltip
$kendo-tooltip-line-height: k-math-div( 20, 14 ) !default;

/// The font size of the Tooltip title.
/// @group tooltip
$kendo-tooltip-title-font-size: calc( var( --kendo-font-size-sm, .75rem ) * 1.25 ) !default;
/// The line height of the Tooltip title.
/// @group tooltip
$kendo-tooltip-title-line-height: var( --kendo-line-height-xs, normal ) !default;

/// The size of the Tooltip callout.
/// @group tooltip
$kendo-tooltip-callout-size: 6px !default;

/// The default background of the Tooltip.
/// @group tooltip
$kendo-tooltip-bg: material-color( gray, 700, .9 ) !default;
/// The default text color of the Tooltip.
/// @group tooltip
$kendo-tooltip-text: get-base-contrast( gray, 700 ) !default;
/// The default border color of the Tooltip.
/// @group tooltip
$kendo-tooltip-border: $kendo-tooltip-bg !default;

/// The box-shadow of the Tooltip.
/// @group tooltip
$kendo-tooltip-shadow: $box-shadow-depth-2 !default;

@function tooltip-theme( $colors ) {
    $_theme: ();

    @each $name, $color in $colors {
        $_theme: k-map-merge(( $name: (
            color: if($kendo-enable-color-system, k-color( on-#{$name} ), k-contrast-color( $color )),
            background-color: $color,
            border: $color,
        )), $_theme );
    }

    @return $_theme;
}

/// The theme colors map for the Tooltip.
/// @group tooltip
$kendo-tooltip-theme-colors: $kendo-theme-colors !default;
/// The generated theme colors map for the Tooltip.
/// @group tooltip
$kendo-tooltip-theme: tooltip-theme( $kendo-tooltip-theme-colors ) !default;
