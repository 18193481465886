// Grid
$kendo-grid-border-width: 1px !default;
$kendo-grid-font-family: var( --kendo-font-family, inherit ) !default;
$kendo-grid-font-size: var( --kendo-font-size, inherit ) !default;
$kendo-grid-line-height: var( --kendo-line-height, normal ) !default;

/// The horizontal padding of the Grid.
/// @group grid
$kendo-grid-padding-x: $kendo-table-md-cell-padding-x !default;
/// The vertical padding of the Grid.
/// @group grid
$kendo-grid-padding-y: $kendo-table-md-cell-padding-y !default;

/// The horizontal padding of the Grid header.
/// @group grid
$kendo-grid-header-padding-x: $kendo-grid-padding-x !default;
/// The vertical padding of the Grid header.
/// @group grid
$kendo-grid-header-padding-y: k-spacing(4) !default;


/// The horizontal padding of the Grid grouping header.
/// @group grid
$kendo-grid-grouping-header-padding-x: k-spacing(2) !default;
/// The vertical padding of the Grid grouping header.
/// @group grid
$kendo-grid-grouping-header-padding-y: $kendo-grid-grouping-header-padding-x !default;

/// The horizontal padding of the Grid cell.
/// @group grid
$kendo-grid-cell-padding-x: $kendo-grid-padding-x !default;
/// The vertical padding of the Grid cell.
/// @group grid
$kendo-grid-cell-padding-y: $kendo-grid-padding-y !default;

/// The horizontal padding of the Grid filter cell.
/// @group grid
$kendo-grid-filter-cell-padding-x: $kendo-grid-padding-x !default;
/// The vertical padding of the Grid filter cell.
/// @group grid
$kendo-grid-filter-cell-padding-y: $kendo-grid-padding-y !default;

/// The horizontal padding of the Grid edit cell.
/// @group grid
$kendo-grid-edit-cell-padding-x: $kendo-grid-cell-padding-x !default;
/// The vertical padding of the Grid filter cell.
/// @group grid
$kendo-grid-edit-cell-padding-y: k-spacing(1.5) !default;

$kendo-grid-group-drop-hint-size: 6px !default;
$kendo-grid-group-drop-hint-line-size: k-math-div( $kendo-grid-group-drop-hint-size, 3 ) !default;
$kendo-grid-group-drop-hint-height: $kendo-chip-calc-size !default;
$kendo-grid-group-drop-hint-top: calc( ( #{$kendo-chip-calc-size} + calc( #{$kendo-grid-grouping-header-padding-y} * 2 ) - #{$kendo-grid-group-drop-hint-height} ) / 2) !default;


$kendo-grid-sm-padding-x: k-spacing(2.5) !default;
$kendo-grid-sm-padding-y: k-spacing(1) !default;
$kendo-grid-sm-header-padding-x: $kendo-grid-sm-padding-x !default;
$kendo-grid-sm-header-padding-y: k-spacing(2.5) !default;
$kendo-grid-sm-grouping-header-padding-x: k-spacing(1.5) !default;
$kendo-grid-sm-grouping-header-padding-y: $kendo-grid-sm-grouping-header-padding-x !default;
$kendo-grid-sm-cell-padding-x: $kendo-grid-sm-padding-x !default;
$kendo-grid-sm-cell-padding-y: $kendo-grid-sm-padding-y !default;
$kendo-grid-sm-filter-cell-padding-x: $kendo-grid-sm-padding-x !default;
$kendo-grid-sm-filter-cell-padding-y: k-spacing(2) !default;
$kendo-grid-sm-edit-cell-padding-x: $kendo-grid-sm-cell-padding-x !default;
$kendo-grid-sm-edit-cell-padding-y: k-spacing(1) !default;
$kendo-grid-sm-group-dropclue-height: calc( #{$kendo-chip-sm-calc-size} + #{$kendo-grid-group-drop-hint-size}) !default;
$kendo-grid-sm-selection-aggregates-padding-x: $kendo-grid-sm-padding-y !default;
$kendo-grid-sm-selection-aggregates-padding-y: $kendo-grid-sm-padding-y !default;

$kendo-grid-md-padding-x: $kendo-grid-padding-x !default;
$kendo-grid-md-padding-y: $kendo-grid-padding-y !default;
$kendo-grid-md-header-padding-x: $kendo-grid-header-padding-x !default;
$kendo-grid-md-header-padding-y: $kendo-grid-header-padding-y !default;
$kendo-grid-md-grouping-header-padding-x: $kendo-grid-grouping-header-padding-x !default;
$kendo-grid-md-grouping-header-padding-y: $kendo-grid-grouping-header-padding-y !default;
$kendo-grid-md-cell-padding-x: $kendo-grid-cell-padding-x !default;
$kendo-grid-md-cell-padding-y: $kendo-grid-cell-padding-y !default;
$kendo-grid-md-filter-cell-padding-x: $kendo-grid-filter-cell-padding-x !default;
$kendo-grid-md-filter-cell-padding-y: $kendo-grid-filter-cell-padding-y !default;
$kendo-grid-md-edit-cell-padding-x: $kendo-grid-edit-cell-padding-x !default;
$kendo-grid-md-edit-cell-padding-y: $kendo-grid-edit-cell-padding-y !default;
$kendo-grid-md-group-dropclue-height: $kendo-grid-group-drop-hint-height !default;
$kendo-grid-md-selection-aggregates-padding-x: k-spacing(2) !default;
$kendo-grid-md-selection-aggregates-padding-y: $kendo-grid-md-selection-aggregates-padding-x !default;


// Kendo Grid sizes
$kendo-grid-sizes: (
    sm: (
        header-padding-x: $kendo-grid-sm-header-padding-x,
        header-padding-y: $kendo-grid-sm-header-padding-y,
        grouping-header-padding-x: $kendo-grid-sm-grouping-header-padding-x,
        grouping-header-padding-y: $kendo-grid-sm-grouping-header-padding-y,
        cell-padding-x: $kendo-grid-sm-cell-padding-x,
        cell-padding-y: $kendo-grid-sm-cell-padding-y,
        filter-cell-padding-x: $kendo-grid-sm-filter-cell-padding-x,
        filter-cell-padding-y: $kendo-grid-sm-filter-cell-padding-y,
        edit-cell-padding-x: $kendo-grid-sm-edit-cell-padding-x,
        edit-cell-padding-y: $kendo-grid-sm-edit-cell-padding-y,
        button-padding-y: $kendo-button-sm-padding-y,
        button-calc-size: $kendo-button-sm-calc-size,
        group-dropclue-height: $kendo-grid-sm-group-dropclue-height,
        selection-aggregates-padding-x: $kendo-grid-sm-selection-aggregates-padding-x,
        selection-aggregates-padding-y: $kendo-grid-sm-selection-aggregates-padding-y
    ),
    md: (
        header-padding-x: $kendo-grid-md-header-padding-x,
        header-padding-y: $kendo-grid-md-header-padding-y,
        grouping-header-padding-x: $kendo-grid-md-grouping-header-padding-x,
        grouping-header-padding-y: $kendo-grid-md-grouping-header-padding-y,
        cell-padding-x: $kendo-grid-md-cell-padding-x,
        cell-padding-y: $kendo-grid-md-cell-padding-y,
        filter-cell-padding-x: $kendo-grid-md-filter-cell-padding-x,
        filter-cell-padding-y: $kendo-grid-md-filter-cell-padding-y,
        edit-cell-padding-x: $kendo-grid-md-edit-cell-padding-x,
        edit-cell-padding-y: $kendo-grid-md-edit-cell-padding-y,
        button-padding-y: $kendo-button-md-padding-y,
        button-calc-size: $kendo-button-md-calc-size,
        group-dropclue-height: $kendo-grid-md-group-dropclue-height,
        selection-aggregates-padding-x: $kendo-grid-md-selection-aggregates-padding-x,
        selection-aggregates-padding-y: $kendo-grid-md-selection-aggregates-padding-y
    ),
) !default;

$kendo-grid-header-font-size: var( --kendo-font-size-sm, inherit ) !default;
$kendo-grid-header-first-border: 1px !default;
$kendo-grid-header-menu-icon-spacing: k-spacing(3.5) !default;

$kendo-grid-toolbar-border-width: $kendo-grid-border-width !default;

$kendo-grid-cell-horizontal-border-width: $kendo-table-cell-horizontal-border-width !default;
$kendo-grid-cell-vertical-border-width: $kendo-table-cell-vertical-border-width !default;

/// The background color of the Grid.
/// @group grid
$kendo-grid-bg: $kendo-table-bg !default;
/// The text color of the Grid.
/// @group grid
$kendo-grid-text: $kendo-table-text !default;
/// The border color of the Grid.
/// @group grid
$kendo-grid-border: $kendo-table-border !default;

/// The background color of Grid header.
/// @group grid
$kendo-grid-header-bg: $kendo-table-header-bg !default;
/// The text color of Grid header.
/// @group grid
$kendo-grid-header-text: $kendo-table-header-text !default;
/// The border color of Grid header.
/// @group grid
$kendo-grid-header-border: $kendo-table-header-border !default;
/// The background gradient of Grid header.
/// @group grid
$kendo-grid-header-gradient: null !default;

/// The background color of Grid footer.
/// @group grid
$kendo-grid-footer-bg: $kendo-table-footer-bg !default;
/// The text color of Grid footer.
/// @group grid
$kendo-grid-footer-text: $kendo-table-footer-text !default;
/// The border color of Grid footer.
/// @group grid
$kendo-grid-footer-border: $kendo-table-footer-border !default;

// TODO(ZHEKOV): we need this, so k-alt won't override it. When we remove k-alt, we can make this null.
/// The background color of the Grid alternating rows.
/// @group grid
$kendo-grid-alt-bg: $kendo-table-alt-row-bg !default;
/// The text color of the Grid alternating rows.
/// @group grid
$kendo-grid-alt-text: $kendo-table-alt-row-text !default;
/// The border color of the Grid alternating rows.
/// @group grid
$kendo-grid-alt-border: $kendo-table-alt-row-border !default;

/// The background color of hovered Grid rows.
/// @group grid
$kendo-grid-hover-bg: $kendo-table-hover-bg !default;
/// The text color of hovered Grid rows.
/// @group grid
$kendo-grid-hover-text: $kendo-table-hover-text !default;
/// The border color of hovered Grid rows.
/// @group grid
$kendo-grid-hover-border: $kendo-table-hover-border !default;

/// The background color of selected Grid rows.
/// @group grid
$kendo-grid-selected-bg: $kendo-table-selected-bg !default;
/// The text color of selected Grid rows.
/// @group grid
$kendo-grid-selected-text: $kendo-table-selected-text !default;
/// The border color of selected Grid rows.
/// @group grid
$kendo-grid-selected-border: $kendo-table-selected-border !default;

/// The background color of the Grid selection aggregates container.
/// @group grid
$kendo-grid-selection-aggregates-bg: $kendo-grid-header-bg !default;
/// The text color of the Grid selection aggregates container.
/// @group grid
$kendo-grid-selection-aggregates-text: $kendo-grid-text !default;
/// The border color of the Grid selection aggregates container.
/// @group grid
$kendo-grid-selection-aggregates-border: $kendo-grid-border !default;
/// The border width of the Grid selection aggregates container.
/// @group grid
$kendo-grid-selection-aggregates-border-width: $kendo-grid-border-width !default;
/// The spacing between the selection aggregates items.
/// @group grid
$kendo-grid-selection-aggregates-spacing: k-spacing(2) !default;
/// The line height of the Grid selection aggregates container.
/// @group grid
$kendo-grid-selection-aggregates-line-height: 20px !default;
/// The font weight of the Grid selection aggregates container.
/// @group grid
$kendo-grid-selection-aggregates-font-weight: var( --kendo-font-weight-bold, normal ) !default;

$kendo-grid-grouping-row-bg: transparent !default;
$kendo-grid-grouping-row-text: $kendo-grid-text !default;

$kendo-grid-sorted-icon-spacing: k-spacing(1) !default;
$kendo-grid-sorted-bg: if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 5%, transparent), rgba( k-contrast-color( $kendo-grid-bg ), .04 )) !default;
$kendo-grid-sorting-indicator-text: $kendo-grid-header-text !default;
$kendo-grid-sorting-index-font-size: var( --kendo-font-size-sm, inherit ) !default;
$kendo-grid-sorting-index-height: $kendo-icon-size !default;
$kendo-grid-sorting-index-spacing-y: calc( #{$kendo-icon-spacing} / 2 ) !default;
$kendo-grid-sorting-index-spacing-x: calc( #{$kendo-grid-sorting-index-spacing-y} * -1 ) !default;

$kendo-grid-focus-shadow: $kendo-table-focus-shadow !default;

$kendo-grid-command-cell-button-spacing: k-spacing(2) !default;
// be cautious when changing the next line; see https://github.com/MoOx/reduce-css-calc/issues/38
$kendo-grid-command-cell-padding-y: calc( #{$kendo-grid-cell-padding-y} - (#{$kendo-button-calc-size} - #{$kendo-line-height-em}) / 2 ) !default;

// Must be a solid color
$kendo-grid-sticky-bg: $kendo-grid-bg !default;
$kendo-grid-sticky-text: $kendo-grid-text !default;
$kendo-grid-sticky-border: if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 50%, transparent), rgba( k-contrast-color( $kendo-grid-bg ), .5 )) !default;

// Must be a solid color
$kendo-grid-sticky-alt-bg: $kendo-grid-sticky-bg !default;

$kendo-grid-sticky-header-bg: $kendo-grid-sticky-bg !default;
$kendo-grid-sticky-header-text: $kendo-grid-header-text !default;
$kendo-grid-sticky-header-border: $kendo-grid-sticky-border !default;

$kendo-grid-sticky-footer-bg: $kendo-grid-sticky-header-bg !default;
$kendo-grid-sticky-footer-hover-bg: $kendo-grid-sticky-footer-bg !default;

$kendo-grid-sticky-selected-bg: if($kendo-enable-color-system, color-mix(in srgb, k-color( primary ) 12%, transparent), k-try-shade($kendo-grid-bg, 4%)) !default;
$kendo-grid-sticky-selected-alt-bg: $kendo-grid-sticky-selected-bg !default;

// Must be a solid color
$kendo-grid-sticky-hover-bg: if($kendo-enable-color-system, k-color( base-hover ), k-try-shade($kendo-grid-bg, 7%)) !default;
$kendo-grid-sticky-selected-hover-bg: if($kendo-enable-color-system, color-mix(in srgb, k-color( primary ) 12%, transparent), k-try-shade($kendo-grid-sticky-selected-bg, .87)) !default;

$kendo-grid-column-menu-width: 250px !default;
$kendo-grid-column-menu-max-width: 320px !default;

$kendo-grid-filter-menu-check-all-border-bottom-width: 0 !default;

$kendo-grid-filter-menu-item-spacing-x: k-spacing(4) !default;
$kendo-grid-filter-menu-item-spacing-y: k-spacing(2) !default;

$kendo-grid-filterable-icon-spacing: calc( #{$kendo-button-calc-size} + #{$kendo-grid-header-menu-icon-spacing} ) !default;

$kendo-grid-column-menu-popup-padding-x: null !default;
$kendo-grid-column-menu-popup-padding-y: null !default;

$kendo-grid-column-menu-item-padding-x: $kendo-menu-popup-item-padding-x !default;
$kendo-grid-column-menu-item-padding-y: $kendo-menu-popup-item-padding-y !default;

$kendo-grid-column-menu-list-item-padding-x: $kendo-list-md-item-padding-x !default;
$kendo-grid-column-menu-list-item-padding-y: $kendo-list-md-item-padding-y !default;

$kendo-grid-column-menu-items-wrap-padding-x: k-spacing(0) !default;
$kendo-grid-column-menu-items-wrap-padding-y: k-spacing(2) !default;

$kendo-grid-column-menu-group-header-padding-x: $kendo-menu-popup-md-item-padding-x !default;
$kendo-grid-column-menu-group-header-padding-y: $kendo-menu-popup-md-item-padding-y !default;

$kendo-grid-column-menu-group-header-font-size: var( --kendo-font-size-sm, inherit ) !default;
$kendo-grid-column-menu-group-header-line-height: var( --kendo-line-height, normal ) !default;
$kendo-grid-column-menu-group-header-text-transform: uppercase !default;
$kendo-grid-column-menu-group-header-border-bottom-width: 1px !default;

$kendo-grid-column-menu-group-header-bg: null !default;
$kendo-grid-column-menu-group-header-text: $kendo-popup-text !default;
$kendo-grid-column-menu-group-header-border: $kendo-popup-border !default;

$kendo-grid-column-menu-tabbed-tabstrip-content-padding-x: k-spacing(0) !default;
$kendo-grid-column-menu-tabbed-tabstrip-content-padding-y: k-spacing(2) !default;

$kendo-grid-column-menu-tabbed-bg: $kendo-base-bg !default;

$kendo-grid-drag-cell-width: ($kendo-icon-size * 3) !default;

$kendo-grid-row-inner-height: calc( #{$kendo-line-height-em} + calc( #{$kendo-grid-cell-padding-y} * 2 ) ) !default;

$kendo-grid-hierarchy-col-width: 32px !default;

$kendo-grid-grouping-row-border-top: 0px !default;
$kendo-grid-group-footer-border-y: 1px !default;
$kendo-grid-group-footer-second-cell-border: 0px !default;

/// The background color of the Grid row resize indicator.
/// @group grid
$kendo-grid-row-resizer-hover-bg: if($kendo-enable-color-system, color-mix(in srgb, k-color( on-base ) 20%, transparent), rgba( k-contrast-color( $kendo-grid-bg ), .24 )) !default;
/// The background color of the active Grid row resize indicator.
/// @group grid
$kendo-grid-row-resizer-active-bg: $kendo-color-primary !default;
/// The height of the Grid row resize indicator.
/// @group grid
$kendo-grid-row-resizer-height: k-spacing(0.5) !default;
