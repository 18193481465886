@import "@progress/kendo-theme-default/scss/upload/_theme.scss";


@mixin kendo-upload--theme() {

    @include kendo-upload--theme-base();

    // Upload
    .k-upload {
        .k-upload-button {
            @include box-shadow( none );
            background-color: if($kendo-enable-color-system, k-color( base-subtle ), k-try-shade( $kendo-upload-dropzone-bg ));
        }
    }

}
