@if ($kendo-enable-color-system) {
    $kendo-color-primary: k-color( primary );
    $kendo-color-primary-contrast: k-color( on-primary );
    $kendo-color-primary-darker: k-color( primary-active );
    $kendo-color-secondary: k-color( secondary );
    $kendo-color-secondary-contrast: k-color( on-secondary );
    $kendo-color-tertiary: k-color( tertiary );
    $kendo-color-info: k-color( info );
    $kendo-color-success: k-color( success );
    $kendo-color-warning: k-color( warning );
    $kendo-color-error: k-color( error );
    $kendo-color-dark: k-color( dark );
    $kendo-color-light: k-color( light );
    $kendo-color-inverse: $kendo-color-dark;
    $kendo-body-bg: k-color( app-surface );
    $kendo-body-text: k-color( on-app-surface );
    $kendo-subtle-text: k-color( subtle );
    $kendo-app-bg: $kendo-body-bg;
    $kendo-app-text: $kendo-body-text;
    $kendo-app-border: k-color( border );
    $kendo-link-text: $kendo-color-secondary;
    $kendo-link-hover-text: k-color( secondary-hover );
    $kendo-component-bg: k-color( surface-alt );
    $kendo-component-text: $kendo-body-text;
    $kendo-component-border: $kendo-app-border;
    $kendo-base-bg: $kendo-body-bg;
    $kendo-base-text: $kendo-body-text;
    $kendo-base-border: $kendo-app-border;
    $kendo-base-gradient: null;
    $kendo-hover-bg: k-color( base-hover );
    $kendo-hover-text: $kendo-base-text;
    $kendo-hover-border: $kendo-base-border;
    $kendo-hover-gradient: null;
    $kendo-selected-bg: $kendo-color-secondary;
    $kendo-selected-text: $kendo-color-secondary-contrast;
    $kendo-selected-border: $kendo-base-border;
    $kendo-selected-gradient: null;
    $kendo-selected-hover-bg: k-color( secondary-hover );
    $kendo-selected-hover-text: $kendo-selected-text;
    $kendo-selected-hover-border: $kendo-base-border;
    $kendo-selected-hover-gradient: null;
    $kendo-disabled-text: k-color( on-app-surface );
    $kendo-disabled-bg: null;
    $kendo-disabled-border: null;
    $kendo-disabled-opacity: .6;
    $kendo-disabled-filter: grayscale(.1);
    $kendo-component-header-bg: $kendo-base-bg;
    $kendo-component-header-text: $kendo-base-text;
    $kendo-component-header-border: $kendo-base-border;
    $kendo-component-header-gradient: null;
    $kendo-invalid-bg: null;
    $kendo-invalid-text: $kendo-color-error;
    $kendo-invalid-border: $kendo-color-error;
    $kendo-invalid-shadow: null;

    $kendo-theme-colors: (
        "primary": $kendo-color-primary,
        "secondary": $kendo-color-secondary,
        "tertiary": $kendo-color-tertiary,
        "info": $kendo-color-info,
        "success": $kendo-color-success,
        "warning": $kendo-color-warning,
        "error": $kendo-color-error,
        "dark": $kendo-color-dark,
        "light": $kendo-color-light,
        "inverse": $kendo-color-inverse,
    );

    $kendo-disabled-styling: (
        bg: $kendo-disabled-bg,
        color: $kendo-disabled-text,
        border: $kendo-disabled-border,
        opacity: $kendo-disabled-opacity,
        filter: $kendo-disabled-filter
    );

}
