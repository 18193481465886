// Floating label

/// The transformation scale of the Floating Label.
/// @group floating-label
$kendo-floating-label-scale: 1 !default;
/// The font size of the Floating Label.
/// @group floating-label
$kendo-floating-label-font-size: $kendo-input-font-size !default;
/// The maximum width of the Floating Label.
/// @group floating-label
$kendo-floating-label-max-width: 90% !default;
/// The line height of the Floating Label.
/// @group floating-label
$kendo-floating-label-line-height: $kendo-input-line-height !default;
/// The height of the Floating Label.
/// @group floating-label
$kendo-floating-label-height: calc( #{$kendo-floating-label-line-height} * #{$kendo-floating-label-font-size} ) !default;
/// The horizontal offset of the Floating Label.
/// @group floating-label
$kendo-floating-label-offset-x: calc( #{$kendo-input-padding-x} + #{$kendo-input-border-width} ) !default;
/// The vertical offset of the Floating Label.
/// @group floating-label
$kendo-floating-label-offset-y: calc( #{$kendo-floating-label-height} + #{$kendo-input-border-width} + #{$kendo-input-padding-y} ) !default;

/// The transformation scale of the focused Floating Label.
/// @group floating-label
$kendo-floating-label-focus-scale: .75 !default;
/// The horizontal offset of the focused Floating Label.
/// @group floating-label
$kendo-floating-label-focus-offset-x: 0px !default;
/// The vertical offset of the focused Floating Label.
/// @group floating-label
$kendo-floating-label-focus-offset-y: 0px !default;

/// The transition of the Floating Label.
/// @group floating-label
$kendo-floating-label-transition: .15s cubic-bezier( .4, 0, .2, 1 ) !default;

/// The background color of the Floating Label.
/// @group floating-label
$kendo-floating-label-bg: null !default;
/// The text color of the Floating Label.
/// @group floating-label
$kendo-floating-label-text: $kendo-subtle-text !default;

/// The background color of the focused Floating Label.
/// @group floating-label
$kendo-floating-label-focus-bg: null !default;
/// The text color of the focused Floating Label.
/// @group floating-label
$kendo-floating-label-focus-text: $kendo-color-primary !default;
