@import "@progress/kendo-theme-core/scss/spacing/index.import.scss";

$kendo-icon-spacing: k-spacing(2) !default;
$kendo-icon-padding: k-spacing(1) !default;

// Metrics
$kendo-padding-x: k-spacing(4) !default;
$kendo-padding-y: k-spacing(1) !default;
$kendo-padding-sm-x: k-spacing(2) !default;
$kendo-padding-sm-y: k-spacing(0.5) !default;
$kendo-padding-md-x: k-spacing(4) !default;
$kendo-padding-md-y: k-spacing(1) !default;
$kendo-padding-lg-x: k-spacing(6) !default;
$kendo-padding-lg-y: k-spacing(1.5) !default;
