// Toolbar

/// The horizontal padding of the Toolbar.
/// @group toolbar
$kendo-toolbar-padding-x: null !default;
/// The horizontal padding of the small Toolbar.
/// @group toolbar
$kendo-toolbar-sm-padding-x: k-spacing(1) !default;
/// The horizontal padding of the medium Toolbar.
/// @group toolbar
$kendo-toolbar-md-padding-x: k-spacing(2) !default;
/// The horizontal padding of the large Toolbar.
/// @group toolbar
$kendo-toolbar-lg-padding-x: k-spacing(2.5) !default;

/// The vertical padding of the Toolbar.
/// @group toolbar
$kendo-toolbar-padding-y: null !default;
/// The vertical padding of the small Toolbar.
/// @group toolbar
$kendo-toolbar-sm-padding-y: k-spacing(1) !default;
/// The vertical padding of the medium Toolbar.
/// @group toolbar
$kendo-toolbar-md-padding-y: k-spacing(2) !default;
/// The vertical padding of the large Toolbar.
/// @group toolbar
$kendo-toolbar-lg-padding-y: k-spacing(2.5) !default;

/// The spacing between the Toolbar tools.
/// @group toolbar
$kendo-toolbar-spacing: null !default;
/// The spacing between the tools of the small Toolbar.
/// @group toolbar
$kendo-toolbar-sm-spacing: k-spacing(1.5) !default;
/// The spacing between the tools of the medium Toolbar.
/// @group toolbar
$kendo-toolbar-md-spacing: k-spacing(2) !default;
/// The spacing between the tools of the large Toolbar.
/// @group toolbar
$kendo-toolbar-lg-spacing: k-spacing(2.5) !default;

/// The width of the border around the Toolbar.
/// @group toolbar
$kendo-toolbar-border-width: 0 !default;
/// The border radius of the Toolbar.
/// @group toolbar
$kendo-toolbar-border-radius: null !default;

/// The font family of the Toolbar.
/// @group toolbar
$kendo-toolbar-font-family: var( --kendo-font-family, inherit ) !default;
/// The font size of the Toolbar.
/// @group toolbar
$kendo-toolbar-font-size: var( --kendo-font-size, inherit ) !default;
/// The line height of the Toolbar.
/// @group toolbar
$kendo-toolbar-line-height: k-math-div( 20, 14 ) !default;

/// The background color of the Toolbar.
/// @group toolbar
$kendo-toolbar-bg: $kendo-component-bg !default;
/// The text color of the Toolbar.
/// @group toolbar
$kendo-toolbar-text: $kendo-component-text !default;
/// The color of the border around the Toolbar.
/// @group toolbar
$kendo-toolbar-border: null !default;
/// The gradient of the Toolbar.
/// @group toolbar
$kendo-toolbar-gradient: null !default;
/// The box shadow of the Toolbar.
/// @group toolbar
$kendo-toolbar-shadow: $box-shadow-depth-4 !default;

/// The color of the separator border of the Toolbar.
/// @group toolbar
$kendo-toolbar-separator-border: $kendo-component-border !default;

/// The width of the input in the Toolbar.
/// @group toolbar
$kendo-toolbar-input-width: 10em !default;

/// The box shadow of the focused Toolbar item.
/// @group toolbar
$kendo-toolbar-item-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px rgba(0, 0, 0, .14), 0 1px 10px rgba(0, 0, 0, .12) !default;

/// Border width of the flat Toolbar.
/// @group toolbar
$kendo-toolbar-flat-border-width: 1px !default;

/// The sizes map for the Toolbar.
/// @group toolbar
$kendo-toolbar-sizes: (
    sm: (
        padding-x: $kendo-toolbar-sm-padding-x,
        padding-y: $kendo-toolbar-sm-padding-y,
        spacing: $kendo-toolbar-sm-spacing
    ),
    md: (
        padding-x: $kendo-toolbar-md-padding-x,
        padding-y: $kendo-toolbar-md-padding-y,
        spacing: $kendo-toolbar-md-spacing
    ),
    lg: (
        padding-x: $kendo-toolbar-lg-padding-x,
        padding-y: $kendo-toolbar-lg-padding-y,
        spacing: $kendo-toolbar-lg-spacing
    )
) !default;
