// Drag hint
$kendo-drag-hint-padding-x: $kendo-padding-md-x !default;
$kendo-drag-hint-padding-y: calc( #{$kendo-padding-md-x} / 2 ) !default;
$kendo-drag-hint-border-width: null !default;
$kendo-drag-hint-border-radius: 9999px !default;
$kendo-drag-hint-font-size: var( --kendo-font-size, inherit ) !default;
$kendo-drag-hint-font-family: var( --kendo-font-family, inherit ) !default;
$kendo-drag-hint-line-height: k-math-div( 20, 14 ) !default;

$kendo-drag-hint-bg: if($kendo-enable-color-system, k-color( base-subtle ), k-try-shade( $kendo-body-bg )) !default;
$kendo-drag-hint-text: $kendo-body-text !default;
$kendo-drag-hint-border: null !default;
$kendo-drag-hint-gradient: null !default;
$kendo-drag-hint-shadow: $box-shadow-depth-3 !default;

$kendo-drag-hint-opacity: null !default;


// Drop hint
$kendo-drop-hint-arrow-size: 8px !default;
$kendo-drop-hint-arrow-spacing: k-math-div( $kendo-drop-hint-arrow-size, 2 ) !default;
$kendo-drop-hint-line-h-width: 20px !default;
$kendo-drop-hint-line-h-height: 1px !default;
$kendo-drop-hint-line-v-width: $kendo-drop-hint-line-h-height !default;
$kendo-drop-hint-line-v-height: $kendo-drop-hint-line-h-width !default;

$kendo-drop-hint-bg: $kendo-color-primary !default;
