@import "@progress/kendo-theme-core/scss/functions/index.import.scss";
@import "@progress/kendo-theme-core/scss/color-system/_constants.scss";
@import "./_palettes.scss";
@import "./_functions.scss";

// Config
$kendo-enable-color-system: true !default;

// Colors
$_default-colors: (
    // Misc
    app-surface: k-map-get( $kendo-palette-gray, white ),
    on-app-surface: k-map-get( $kendo-palette-gray, 15 ),
    subtle: k-map-get( $kendo-palette-gray, 12 ),
    surface: k-map-get( $kendo-palette-gray, 2 ),
    surface-alt: k-map-get( $kendo-palette-gray, white ),
    border: rgba( k-map-get( $kendo-palette-gray, black ), .12),
    border-alt: rgba( k-map-get( $kendo-palette-gray, black ), .15),
    // Base
    base-subtle: k-map-get( $kendo-palette-gray, 4 ),
    base-subtle-hover: k-map-get( $kendo-palette-gray, 6 ),
    base-subtle-active: k-map-get( $kendo-palette-gray, 8 ),
    base: k-map-get( $kendo-palette-gray, white ),
    base-hover: k-map-get( $kendo-palette-gray, 4 ),
    base-active: k-map-get( $kendo-palette-gray, 6 ),
    base-emphasis: k-map-get( $kendo-palette-gray, 10 ),
    base-on-subtle: k-map-get( $kendo-palette-gray, 15 ),
    on-base: k-map-get( $kendo-palette-gray, 15 ),
    base-on-surface: k-map-get( $kendo-palette-gray, 15 ),
    // Primary
    primary-subtle: k-map-get( $kendo-palette-indigo, 4 ),
    primary-subtle-hover: k-map-get( $kendo-palette-indigo, 5 ),
    primary-subtle-active: k-map-get( $kendo-palette-indigo, 6 ),
    primary: k-map-get( $kendo-palette-indigo, 8 ),
    primary-hover: k-map-get( $kendo-palette-indigo, 9 ),
    primary-active: k-map-get( $kendo-palette-indigo, 11 ),
    primary-emphasis: k-map-get( $kendo-palette-indigo, 7 ),
    primary-on-subtle: k-map-get( $kendo-palette-indigo, 15 ),
    on-primary: k-map-get( $kendo-palette-gray, white ),
    primary-on-surface: k-map-get( $kendo-palette-indigo, 8 ),
    // Secondary
    secondary-subtle: k-map-get( $kendo-palette-pink, 4 ),
    secondary-subtle-hover: k-map-get( $kendo-palette-pink, 5 ),
    secondary-subtle-active: k-map-get( $kendo-palette-pink, 6 ),
    secondary: k-map-get( $kendo-palette-pink, 8 ),
    secondary-hover: k-map-get( $kendo-palette-pink, 9 ),
    secondary-active: k-map-get( $kendo-palette-pink, 11 ),
    secondary-emphasis: k-map-get( $kendo-palette-pink, 7 ),
    secondary-on-subtle: k-map-get( $kendo-palette-pink, 15 ),
    on-secondary: k-map-get( $kendo-palette-gray, white ),
    secondary-on-surface: k-map-get( $kendo-palette-pink, 13 ),
    // Tertiary
    tertiary-subtle: k-map-get( $kendo-palette-dark-teal, 4 ),
    tertiary-subtle-hover: k-map-get( $kendo-palette-dark-teal, 5 ),
    tertiary-subtle-active: k-map-get( $kendo-palette-dark-teal, 6 ),
    tertiary: k-map-get( $kendo-palette-dark-teal, 8 ),
    tertiary-hover: k-map-get( $kendo-palette-dark-teal, 9 ),
    tertiary-active: k-map-get( $kendo-palette-dark-teal, 11 ),
    tertiary-emphasis: k-map-get( $kendo-palette-dark-teal, 7 ),
    tertiary-on-subtle: k-map-get( $kendo-palette-dark-teal, 15 ),
    on-tertiary: k-map-get( $kendo-palette-gray, white ),
    tertiary-on-surface: k-map-get( $kendo-palette-dark-teal, 13 ),
    // Info
    info-subtle: k-map-get( $kendo-palette-blue, 4 ),
    info-subtle-hover: k-map-get( $kendo-palette-blue, 5 ),
    info-subtle-active: k-map-get( $kendo-palette-blue, 6 ),
    info: k-map-get( $kendo-palette-blue, 8 ),
    info-hover: k-map-get( $kendo-palette-blue, 9 ),
    info-active: k-map-get( $kendo-palette-blue, 11 ),
    info-emphasis: k-map-get( $kendo-palette-blue, 7 ),
    info-on-subtle: k-map-get( $kendo-palette-blue, 15 ),
    on-info: k-map-get( $kendo-palette-gray, white ),
    info-on-surface: k-map-get( $kendo-palette-blue, 13 ),
    // Success
    success-subtle: k-map-get( $kendo-palette-green, 4 ),
    success-subtle-hover: k-map-get( $kendo-palette-green, 5 ),
    success-subtle-active: k-map-get( $kendo-palette-green, 6 ),
    success: k-map-get( $kendo-palette-green, 8 ),
    success-hover: k-map-get( $kendo-palette-green, 9 ),
    success-active: k-map-get( $kendo-palette-green, 11 ),
    success-emphasis: k-map-get( $kendo-palette-green, 7 ),
    success-on-subtle: k-map-get( $kendo-palette-green, 15 ),
    on-success: k-map-get( $kendo-palette-gray, white ),
    success-on-surface: k-map-get( $kendo-palette-green, 13 ),
    // Warning
    warning-subtle: k-map-get( $kendo-palette-yellow, 4 ),
    warning-subtle-hover: k-map-get( $kendo-palette-yellow, 5 ),
    warning-subtle-active: k-map-get( $kendo-palette-yellow, 6 ),
    warning: k-map-get( $kendo-palette-yellow, 8 ),
    warning-hover: k-map-get( $kendo-palette-yellow, 9 ),
    warning-active: k-map-get( $kendo-palette-yellow, 11 ),
    warning-emphasis: k-map-get( $kendo-palette-yellow, 7 ),
    warning-on-subtle: k-map-get( $kendo-palette-yellow, 15 ),
    on-warning: k-map-get( $kendo-palette-gray, black ),
    warning-on-surface: k-map-get( $kendo-palette-yellow, 8 ),
    // Error
    error-subtle: k-map-get( $kendo-palette-red, 4 ),
    error-subtle-hover: k-map-get( $kendo-palette-red, 5 ),
    error-subtle-active: k-map-get( $kendo-palette-red, 6 ),
    error: k-map-get( $kendo-palette-red, 8 ),
    error-hover: k-map-get( $kendo-palette-red, 9 ),
    error-active: k-map-get( $kendo-palette-red, 11 ),
    error-emphasis: k-map-get( $kendo-palette-red, 7 ),
    error-on-subtle: k-map-get( $kendo-palette-red, 15 ),
    on-error: k-map-get( $kendo-palette-gray, white ),
    error-on-surface: k-map-get( $kendo-palette-red, 13 ),
    // Light
    light-subtle: k-map-get( $kendo-palette-gray, 1 ),
    light-subtle-hover: k-map-get( $kendo-palette-gray, 2 ),
    light-subtle-active: k-map-get( $kendo-palette-gray, 3 ),
    light: k-map-get( $kendo-palette-gray, 2 ),
    light-hover: k-map-get( $kendo-palette-gray, 4 ),
    light-active: k-map-get( $kendo-palette-gray, 6 ),
    light-emphasis: k-map-get( $kendo-palette-gray, 5 ),
    light-on-subtle: k-map-get( $kendo-palette-gray, 15 ),
    on-light: k-map-get( $kendo-palette-gray, black ),
    light-on-surface: k-map-get( $kendo-palette-gray, 4 ),
    // Dark
    dark-subtle: k-map-get( $kendo-palette-gray, 7 ),
    dark-subtle-hover: k-map-get( $kendo-palette-gray, 8 ),
    dark-subtle-active: k-map-get( $kendo-palette-gray, 9 ),
    dark: k-map-get( $kendo-palette-gray, 14 ),
    dark-hover: k-map-get( $kendo-palette-gray, 15 ),
    dark-active: k-map-get( $kendo-palette-gray, black ),
    dark-emphasis: k-map-get( $kendo-palette-gray, 11 ),
    dark-on-subtle: k-map-get( $kendo-palette-gray, 15 ),
    on-dark: k-map-get( $kendo-palette-gray, white ),
    dark-on-surface: k-map-get( $kendo-palette-gray, 13 ),
    // Inverse
    inverse-subtle: k-map-get( $kendo-palette-gray, 7 ),
    inverse-subtle-hover: k-map-get( $kendo-palette-gray, 8 ),
    inverse-subtle-active: k-map-get( $kendo-palette-gray, 9 ),
    inverse: k-map-get( $kendo-palette-gray, 14 ),
    inverse-hover: k-map-get( $kendo-palette-gray, 15 ),
    inverse-active: k-map-get( $kendo-palette-gray, black ),
    inverse-emphasis: k-map-get( $kendo-palette-gray, 11 ),
    inverse-on-subtle: k-map-get( $kendo-palette-gray, 15 ),
    on-inverse: k-map-get( $kendo-palette-gray, white ),
    inverse-on-surface: k-map-get( $kendo-palette-gray, 13 ),
    // Series A
    series-a: k-map-get( $kendo-palette-deep-purple, 8 ),
    series-a-bold: k-map-get( $kendo-palette-deep-purple, 10 ),
    series-a-bolder: k-map-get( $kendo-palette-deep-purple, 12 ),
    series-a-subtle: k-map-get( $kendo-palette-deep-purple, 6 ),
    series-a-subtler: k-map-get( $kendo-palette-deep-purple, 4 ),
    // Series B
    series-b: k-map-get( $kendo-palette-baby-blue, 8 ),
    series-b-bold: k-map-get( $kendo-palette-baby-blue, 10 ),
    series-b-bolder: k-map-get( $kendo-palette-baby-blue, 12 ),
    series-b-subtle: k-map-get( $kendo-palette-baby-blue, 6 ),
    series-b-subtler: k-map-get( $kendo-palette-baby-blue, 4 ),
    // Series C
    series-c: k-map-get( $kendo-palette-teal, 8 ),
    series-c-bold: k-map-get( $kendo-palette-teal, 10 ),
    series-c-bolder: k-map-get( $kendo-palette-teal, 12 ),
    series-c-subtle: k-map-get( $kendo-palette-teal, 6 ),
    series-c-subtler: k-map-get( $kendo-palette-teal, 4 ),
    // Series D
    series-d: k-map-get( $kendo-palette-canary-yellow, 8 ),
    series-d-bold: k-map-get( $kendo-palette-canary-yellow, 10 ),
    series-d-bolder: k-map-get( $kendo-palette-canary-yellow, 12 ),
    series-d-subtle: k-map-get( $kendo-palette-canary-yellow, 6 ),
    series-d-subtler: k-map-get( $kendo-palette-canary-yellow, 4 ),
    // Series Е
    series-e: k-map-get( $kendo-palette-candy-red, 8 ),
    series-e-bold: k-map-get( $kendo-palette-candy-red, 10 ),
    series-e-bolder: k-map-get( $kendo-palette-candy-red, 12 ),
    series-e-subtle: k-map-get( $kendo-palette-candy-red, 6 ),
    series-e-subtler: k-map-get( $kendo-palette-candy-red, 4 ),
    // Series F
    series-f: k-map-get( $kendo-palette-forest-green, 8 ),
    series-f-bold: k-map-get( $kendo-palette-forest-green, 10 ),
    series-f-bolder: k-map-get( $kendo-palette-forest-green, 12 ),
    series-f-subtle: k-map-get( $kendo-palette-forest-green, 6 ),
    series-f-subtler: k-map-get( $kendo-palette-forest-green, 4 ),
) !default;

/// The global default Colors map.
/// @group color-system
///
/// @subgroup {misc}
/// [app-surface, on-app-surface, subtle,
/// surface, surface-alt, border, border-alt]
/// The Misc variable group.
/// @prop {Color} app-surface - The background color of the application.
/// @prop {Color} on-app-surface - The text color of the application.
/// @prop {Color} subtle - The subtle text color.
/// @prop {Color} surface - The background color of the components' chrome area.
/// @prop {Color} surface-alt - The alternative background color of the components' chrome area.
/// @prop {Color} border - Тhe border color of the application.
/// @prop {Color} border-alt - Тhe alternative border color of the application.
///
/// @subgroup {base}
/// [base-subtle, base-subtle-hover, base-subtle-active,
/// base, base-hover, base-active, base-emphasis,
/// base-on-subtle, on-base, base-on-surface]
/// The Base variable group.
/// @prop {Color} base-subtle - The base subtle background color variable.
/// @prop {Color} base-subtle-hover - The base subtle background color variable for the hover state.
/// @prop {Color} base-subtle-active - The base subtle background color variable for the active state.
/// @prop {Color} base - The base background color variable.
/// @prop {Color} base-hover - The base background color variable for the hover state.
/// @prop {Color} base-active - The base background color variable for the active state.
/// @prop {Color} base-emphasis - The emphasized base color variable.
/// @prop {Color} base-on-subtle - The text color variable for content on base subtle.
/// @prop {Color} on-base - The text color variable for content on base.
/// @prop {Color} base-on-surface - The text color variable for content on surface.
///
/// @subgroup {primary}
/// [primary-subtle, primary-subtle-hover, primary-subtle-active,
/// primary, primary-hover, primary-active, primary-emphasis,
/// primary-on-subtle, on-primary, primary-on-surface]
/// The Primary variable group.
/// @prop {Color} primary-subtle - The primary subtle background color variable.
/// @prop {Color} primary-subtle-hover - The primary subtle background color variable for the hover state.
/// @prop {Color} primary-subtle-active - The primary subtle background color variable for the active state.
/// @prop {Color} primary - The primary background color variable.
/// @prop {Color} primary-hover - The primary background color variable for the hover state.
/// @prop {Color} primary-active - The primary background color variable for the active state.
/// @prop {Color} primary-emphasis - The emphasized primary color variable.
/// @prop {Color} primary-on-subtle - The text color variable for content on primary subtle.
/// @prop {Color} on-primary - The text color variable for content on primary.
/// @prop {Color} primary-on-surface - The text color variable for content on surface.
///
/// @subgroup {secondary}
/// [secondary-subtle, secondary-subtle-hover, secondary-subtle-active,
/// secondary, secondary-hover, secondary-active, secondary-emphasis,
/// secondary-on-subtle, on-secondary, secondary-on-surface]
/// The Secondary variable group.
/// @prop {Color} secondary-subtle - The secondary subtle background color variable.
/// @prop {Color} secondary-subtle-hover - The secondary subtle background color variable for the hover state.
/// @prop {Color} secondary-subtle-active - The secondary subtle background color variable for the active state.
/// @prop {Color} secondary - The secondary background color variable.
/// @prop {Color} secondary-hover - The secondary background color variable for the hover state.
/// @prop {Color} secondary-active - The secondary background color variable for the active state.
/// @prop {Color} secondary-emphasis - The emphasized secondary color variable.
/// @prop {Color} secondary-on-subtle - The text color variable for content on secondary subtle.
/// @prop {Color} on-secondary - The text color variable for content on secondary.
/// @prop {Color} secondary-on-surface - The text color variable for content on surface.
///
/// @subgroup {tertiary}
/// [tertiary-subtle, tertiary-subtle-hover, tertiary-subtle-active,
/// tertiary, tertiary-hover, tertiary-active, tertiary-emphasis,
/// tertiary-on-subtle, on-tertiary, tertiary-on-surface]
/// The Tertiary variable group.
/// @prop {Color} tertiary-subtle - The tertiary subtle background color variable.
/// @prop {Color} tertiary-subtle-hover - The tertiary subtle background color variable for the hover state.
/// @prop {Color} tertiary-subtle-active - The tertiary subtle background color variable for the active state.
/// @prop {Color} tertiary - The tertiary background color variable.
/// @prop {Color} tertiary-hover - The tertiary background color variable for the hover state.
/// @prop {Color} tertiary-active - The tertiary background color variable for the active state.
/// @prop {Color} tertiary-emphasis - The emphasized tertiary color variable.
/// @prop {Color} tertiary-on-subtle - The text color variable for content on tertiary subtle.
/// @prop {Color} on-tertiary - The text color variable for content on tertiary.
/// @prop {Color} tertiary-on-surface - The text color variable for content on surface.
///
/// @subgroup {info}
/// [info-subtle, info-subtle-hover, info-subtle-active,
/// info, info-hover, info-active, info-emphasis,
/// info-on-subtle, on-info, info-on-surface]
/// The Info variable group.
/// @prop {Color} info-subtle - The info subtle background color variable.
/// @prop {Color} info-subtle-hover - The info subtle background color variable for the hover state.
/// @prop {Color} info-subtle-active - The info subtle background color variable for the active state.
/// @prop {Color} info - The info background color variable.
/// @prop {Color} info-hover - The info background color variable for the hover state.
/// @prop {Color} info-active - The info background color variable for the active state.
/// @prop {Color} info-emphasis - The emphasized info color variable.
/// @prop {Color} info-on-subtle - The text color variable for content on info subtle.
/// @prop {Color} on-info - The text color variable for content on info.
/// @prop {Color} info-on-surface - The text color variable for content on surface.
///
/// @subgroup {success}
/// [success-subtle, success-subtle-hover, success-subtle-active,
/// success, success-hover, success-active, success-emphasis,
/// success-on-subtle, on-success, success-on-surface]
/// The Success variable group.
/// @prop {Color} success-subtle - The success subtle background color variable.
/// @prop {Color} success-subtle-hover - The success subtle background color variable for the hover state.
/// @prop {Color} success-subtle-active - The success subtle background color variable for the active state.
/// @prop {Color} success - The success background color variable.
/// @prop {Color} success-hover - The success background color variable for the hover state.
/// @prop {Color} success-active - The success background color variable for the active state.
/// @prop {Color} success-emphasis - The emphasized success color variable.
/// @prop {Color} success-on-subtle - The text color variable for content on success subtle.
/// @prop {Color} on-success - The text color variable for content on success.
/// @prop {Color} success-on-surface - The text color variable for content on surface.
///
/// @subgroup {warning}
/// [warning-subtle, warning-subtle-hover, warning-subtle-active,
/// warning, warning-hover, warning-active, warning-emphasis,
/// warning-on-subtle, on-warning, warning-on-surface]
/// The Warning variable group.
/// @prop {Color} warning-subtle - The warning subtle background color variable.
/// @prop {Color} warning-subtle-hover - The warning subtle background color variable for the hover state.
/// @prop {Color} warning-subtle-active - The warning subtle background color variable for the active state.
/// @prop {Color} warning - The warning background color variable.
/// @prop {Color} warning-hover - The warning background color variable for the hover state.
/// @prop {Color} warning-active - The warning background color variable for the active state.
/// @prop {Color} warning-emphasis - The emphasized warning color variable.
/// @prop {Color} warning-on-subtle - The text color variable for content on warning subtle.
/// @prop {Color} on-warning - The text color variable for content on warning.
/// @prop {Color} warning-on-surface - The text color variable for content on surface.
///
/// @subgroup {error}
/// [error-subtle, error-subtle-hover, error-subtle-active,
/// error, error-hover, error-active, error-emphasis,
/// error-on-subtle, on-error, error-on-surface]
/// The Error variable group.
/// @prop {Color} error-subtle - The error subtle background color variable.
/// @prop {Color} error-subtle-hover - The error subtle background color variable for the hover state.
/// @prop {Color} error-subtle-active - The error subtle background color variable for the active state.
/// @prop {Color} error - The error background color variable.
/// @prop {Color} error-hover - The error background color variable for the hover state.
/// @prop {Color} error-active - The error background color variable for the active state.
/// @prop {Color} error-emphasis - The emphasized error color variable.
/// @prop {Color} error-on-subtle - The text color variable for content on error subtle.
/// @prop {Color} on-error - The text color variable for content on error.
/// @prop {Color} error-on-surface - The text color variable for content on surface.
///
/// @subgroup {light}
/// [light-subtle, light-subtle-hover, light-subtle-active,
/// light, light-hover, light-active, light-emphasis,
/// light-on-subtle, on-light, light-on-surface]
/// The Light variable group.
/// @prop {Color} light-subtle - The light subtle background color variable.
/// @prop {Color} light-subtle-hover - The light subtle background color variable for the hover state.
/// @prop {Color} light-subtle-active - The light subtle background color variable for the active state.
/// @prop {Color} light - The light background color variable.
/// @prop {Color} light-hover - The light background color variable for the hover state.
/// @prop {Color} light-active - The light background color variable for the active state.
/// @prop {Color} light-emphasis - The emphasized light color variable.
/// @prop {Color} light-on-subtle - The text color variable for content on light subtle.
/// @prop {Color} on-light - The text color variable for content on light.
/// @prop {Color} light-on-surface - The text color variable for content on surface.
///
/// @subgroup {dark}
/// [dark-subtle, dark-subtle-hover, dark-subtle-active,
/// dark, dark-hover, dark-active, dark-emphasis,
/// dark-on-subtle, on-dark, dark-on-surface]
/// The Dark variable group.
/// @prop {Color} dark-subtle - The dark subtle background color variable.
/// @prop {Color} dark-subtle-hover - The dark subtle background color variable for the hover state.
/// @prop {Color} dark-subtle-active - The dark subtle background color variable for the active state.
/// @prop {Color} dark - The dark background color variable.
/// @prop {Color} dark-hover - The dark background color variable for the hover state.
/// @prop {Color} dark-active - The dark background color variable for the active state.
/// @prop {Color} dark-emphasis - The emphasized dark color variable.
/// @prop {Color} dark-on-subtle - The text color variable for content on dark subtle.
/// @prop {Color} on-dark - The text color variable for content on dark.
/// @prop {Color} dark-on-surface - The text color variable for content on surface.
///
/// @subgroup {inverse}
/// [inverse-subtle, inverse-subtle-hover, inverse-subtle-active,
/// inverse, inverse-hover, inverse-active, inverse-emphasis,
/// inverse-on-subtle, on-inverse, inverse-on-surface]
/// The Inverse variable group.
/// @prop {Color} inverse-subtle - The inverse subtle background color variable.
/// @prop {Color} inverse-subtle-hover - The inverse subtle background color variable for the hover state.
/// @prop {Color} inverse-subtle-active - The inverse subtle background color variable for the active state.
/// @prop {Color} inverse - The inverse background color variable.
/// @prop {Color} inverse-hover - The inverse background color variable for the hover state.
/// @prop {Color} inverse-active - The inverse background color variable for the active state.
/// @prop {Color} inverse-emphasis - The emphasized inverse color variable.
/// @prop {Color} inverse-on-subtle - The text color variable for content on inverse subtle.
/// @prop {Color} on-inverse - The text color variable for content on inverse.
/// @prop {Color} inverse-on-surface - The text color variable for content on surface.
///
/// @subgroup {series-a}
/// [series-a, series-a-bold, series-a-bolder,
/// series-a-subtle, series-a-subtler]
/// The Series A variable group.
/// @prop {Color} series-a - The series A color variable.
/// @prop {Color} series-a-bold - The bold series A color variable.
/// @prop {Color} series-a-bolder - The bolder series A color variable.
/// @prop {Color} series-a-subtle - The subtle series A color variable.
/// @prop {Color} series-a-subtler - The subtler series A color variable.
///
/// @subgroup {series-b}
/// [series-b, series-b-bold, series-b-bolder,
/// series-b-subtle, series-b-subtler]
/// The Series B variable group.
/// @prop {Color} series-b - The series B color variable.
/// @prop {Color} series-b-bold - The bold series B color variable.
/// @prop {Color} series-b-bolder - The bolder series B color variable.
/// @prop {Color} series-b-subtle - The subtle series B color variable.
/// @prop {Color} series-b-subtler - The subtler series B color variable.
///
/// @subgroup {series-c}
/// [series-c, series-c-bold, series-c-bolder,
/// series-c-subtle, series-c-subtler]
/// The Series C variable group.
/// @prop {Color} series-c - The series C color variable.
/// @prop {Color} series-c-bold - The bold series C color variable.
/// @prop {Color} series-c-bolder - The bolder series C color variable.
/// @prop {Color} series-c-subtle - The subtle series C color variable.
/// @prop {Color} series-c-subtler - The subtler series C color variable.
///
/// @subgroup {series-d}
/// [series-d, series-d-bold, series-d-bolder,
/// series-d-subtle, series-d-subtler]
/// The Series D variable group.
/// @prop {Color} series-d - The series D color variable.
/// @prop {Color} series-d-bold - The bold series D color variable.
/// @prop {Color} series-d-bolder - The bolder series D color variable.
/// @prop {Color} series-d-subtle - The subtle series D color variable.
/// @prop {Color} series-d-subtler - The subtler series D color variable.
///
/// @subgroup {series-e}
/// [series-e, series-e-bold, series-e-bolder,
/// series-e-subtle, series-e-subtler]
/// The Series E variable group.
/// @prop {Color} series-e - The series E color variable.
/// @prop {Color} series-e-bold - The bold series E color variable.
/// @prop {Color} series-e-bolder - The bolder series E color variable.
/// @prop {Color} series-e-subtle - The subtle series E color variable.
/// @prop {Color} series-e-subtler - The subtler series E color variable.
///
/// @subgroup {series-f}
/// [series-f, series-f-bold, series-f-bolder,
/// series-f-subtle, series-f-subtler]
/// The Series F variable group.
/// @prop {Color} series-f - The series F color variable.
/// @prop {Color} series-f-bold - The bold series F color variable.
/// @prop {Color} series-f-bolder - The bolder series F color variable.
/// @prop {Color} series-f-subtle - The subtle series F color variable.
/// @prop {Color} series-f-subtler - The subtler series F color variable.
$kendo-colors: $_default-colors !default;

// Adapted from https://github.com/angular/material2/blob/master/src/lib/core/theming/_palette.scss
// Adapted from https://github.com/angular/components/blob/master/src/material/core/theming/_theming.scss
$dark-primary-text: rgba(black, 0.87) !default;
$dark-secondary-text: rgba(black, 0.54) !default;
$dark-disabled-text: rgba(black, 0.38) !default;
$dark-dividers: rgba(black, 0.12) !default;
$light-primary-text: $kendo-color-white !default;
$light-secondary-text: rgba(white, 0.7) !default;
$light-disabled-text: rgba(white, 0.5) !default;
$light-dividers: rgba(white, 0.12) !default;

$material-dark-complimentary: (
    is-dark: true,
    body-bg: #121212,
    body-text: $light-primary-text,
    subtle-text: $light-secondary-text,
    disabled-text: $light-disabled-text,
    component-bg: #121212,
    component-text: $light-primary-text,
    component-border: $light-dividers,
    base-bg: get-base-hue( gray, 900 ),
    hover-bg: rgba(white, 0.08),
    focus-bg: rgba(white, 0.24),
    elevation: $kendo-color-black,
) !default;

$material-light-complimentary: (
    is-dark: false,
    body-bg: $kendo-color-white,
    body-text: get-base-contrast(gray, 50),
    subtle-text: $dark-secondary-text,
    disabled-text: $dark-disabled-text,
    component-bg: $kendo-color-white,
    component-text: $dark-primary-text,
    component-border: $dark-dividers,
    base-bg: $kendo-color-white,
    hover-bg: rgba(black, 0.04),
    focus-bg: rgba(black, 0.12),
    elevation: $kendo-color-black,
) !default;

$primary-palette-name: indigo !default;
$secondary-palette-name: pink !default;
$tertiary-palette-name: teal !default;
$adjust-contrast: true !default;
$theme-type: light !default; // light or dark

$primary-palette: material-palette( $primary-palette-name, 500, 300, 700, $adjust-contrast ) !default;
$secondary-palette: material-palette( $secondary-palette-name, 500, 300, 700, $adjust-contrast ) !default;
$tertiary-palette: material-palette( $tertiary-palette-name, 500, 300, 700, $adjust-contrast ) !default;

$theme: material-theme(
    $primary-palette,
    $secondary-palette,
    $theme-type
) !default;

// Color settings
$kendo-is-dark-theme: k-map-get( $theme, is-dark ) !default;

/// Color of shadows
/// @group shadows
$elevation: k-map-get( $theme, elevation ) !default;

// Theme colors
/// The color that focuses the user attention.
/// Used for primary buttons and for elements of primary importance across the theme.
/// @group color-system
/// @type Color
$kendo-color-primary: material-color( $primary-palette, main ) !default;
$kendo-color-primary-lighter: material-color( $primary-palette, lighter ) !default;
$kendo-color-primary-darker: material-color( $primary-palette, darker ) !default;

/// The color used along with the primary color denoted by $kendo-color-primary.
/// Used to provide contrast between the background and foreground colors.
/// @group color-system
/// @type Color
$kendo-color-primary-contrast: material-color( $primary-palette, main-contrast ) !default;

/// The secondary color of the theme.
/// @group color-system
/// @type Color
$kendo-color-secondary: material-color( $secondary-palette, main ) !default;
$kendo-color-secondary-lighter: material-color( $secondary-palette, lighter ) !default;
$kendo-color-secondary-darker: material-color( $secondary-palette, darker ) !default;

/// The color used along with the secondary color denoted by $kendo-color-secondary.
/// Used to provide contrast between the background and foreground colors.
/// @group color-system
/// @type Color
$kendo-color-secondary-contrast: material-color( $secondary-palette, main-contrast ) !default;

/// The tertiary color of the theme.
/// @group color-system
/// @type Color
$kendo-color-tertiary: material-color($tertiary-palette, main) !default;
$kendo-color-tertiary-lighter: material-color($tertiary-palette, lighter) !default;
$kendo-color-tertiary-darker: material-color($tertiary-palette, darker) !default;

/// The color used along with the tertiary color denoted by $kendo-color-tertiary.
/// Used to provide contrast between the background and foreground colors.
/// @group color-system
/// @type Color
$kendo-color-tertiary-contrast: material-color($tertiary-palette, main-contrast) !default;

/// The color for informational messages and states.
/// @group color-system
/// @type Color
$kendo-color-info: #0058e9 !default;
$kendo-color-info-lighter: k-color-tint( $kendo-color-info, 2 ) !default;
$kendo-color-info-darker: k-color-shade( $kendo-color-info, 2 ) !default;

/// The color for success messages and states.
/// @group color-system
/// @type Color
$kendo-color-success: #37b400 !default;
$kendo-color-success-lighter: k-color-tint( $kendo-color-success, 2 ) !default;
$kendo-color-success-darker: k-color-shade( $kendo-color-success, 2 ) !default;

/// The color for warning messages and states.
/// @group color-system
/// @type Color
$kendo-color-warning: #ffc000 !default;
$kendo-color-warning-lighter: k-color-tint( $kendo-color-warning, 2 ) !default;
$kendo-color-warning-darker: k-color-shade( $kendo-color-warning, 2 ) !default;

/// The color for error messages and states.
/// @group color-system
/// @type Color
$kendo-color-error: #f31700 !default;
$kendo-color-error-lighter: k-color-tint( $kendo-color-error, 2 ) !default;
$kendo-color-error-darker: k-color-shade( $kendo-color-error, 2 ) !default;

/// The dark color of the theme.
/// @group color-system
/// @type Color
$kendo-color-dark: get-base-hue( gray, 800 ) !default;

/// The light color of the theme.
/// @group color-system
/// @type Color
$kendo-color-light: get-base-hue( gray, 100 ) !default;

/// Inverse color of the theme. Depending on the theme luminance dark or light, it will be light or dark
/// @group color-system
$kendo-color-inverse: if( $kendo-is-dark-theme, $kendo-color-light, $kendo-color-dark ) !default;

$kendo-theme-colors: (
    "primary": $kendo-color-primary,
    "secondary": $kendo-color-secondary,
    "tertiary": $kendo-color-tertiary,
    "info": $kendo-color-info,
    "success": $kendo-color-success,
    "warning": $kendo-color-warning,
    "error": $kendo-color-error,
    "dark": $kendo-color-dark,
    "light": $kendo-color-light,
    "inverse": $kendo-color-inverse
) !default;

// Generic styles

// Root styles
$kendo-body-bg: k-map-get( $theme, body-bg ) !default;
$kendo-body-text: k-map-get( $theme, body-text ) !default;

$kendo-subtle-text: k-map-get( $theme, subtle-text ) !default;

$kendo-app-bg: $kendo-body-bg !default;
$kendo-app-text: $kendo-body-text !default;
$kendo-app-border: k-map-get( $theme, component-border ) !default;

// Link
$kendo-link-text: $kendo-color-secondary !default;
$kendo-link-hover-text: $kendo-color-secondary-darker !default;

// Components

// Component
/// Background color of a component.
/// Note: do not use this variable directly. Instead derive it as `$component-name-bg` e.g. `$kendo-grid-bg: $kendo-component-bg !default;`.
/// @group component
$kendo-component-bg: k-map-get( $theme, component-bg ) !default;
/// Text color of a component.
/// Note: do not use this variable directly. Instead derive it as `$component-name-text` e.g. `$kendo-grid-text: $kendo-component-text !default;`.
/// @group component
$kendo-component-text: k-map-get( $theme, component-text ) !default;
/// Border color of a component.
/// Note: do not use this variable directly. Instead derive it as `$component-name-border` e.g. `$kendo-grid-border: $kendo-component-border !default;`.
/// @group component
$kendo-component-border: k-map-get( $theme, component-border ) !default;

/// The background of the components' chrome area.
$kendo-base-bg: k-map-get( $theme, base-bg ) !default;
/// The text color of the components' chrome area.
$kendo-base-text: k-map-get( $theme, component-text ) !default;
/// The border of the components' chrome area.
$kendo-base-border: rgba( if( $kendo-is-dark-theme, $kendo-color-white, $kendo-color-black ), .08 ) !default;
/// The gradient background of the components' chrome area.
$kendo-base-gradient: null !default;

/// The background of hovered items.
$kendo-hover-bg: k-map-get( $theme, hover-bg ) !default;
/// The text color of hovered items.
$kendo-hover-text: $kendo-base-text !default;
/// The border color of hovered items.
$kendo-hover-border: rgba( $kendo-base-border, .15 ) !default;
/// The gradient background of hovered items.
$kendo-hover-gradient: null !default;

/// The background of selected items.
$kendo-selected-bg: $kendo-color-secondary !default;
/// The text color of selected items.
$kendo-selected-text: $kendo-color-secondary-contrast !default;
/// The border color of selected items.
$kendo-selected-border: rgba( $kendo-base-border, .1 ) !default;
/// The gradient background of selected items.
$kendo-selected-gradient: null !default;

$kendo-selected-hover-bg: k-try-shade( $kendo-selected-bg, .5 ) !default;
$kendo-selected-hover-text: $kendo-selected-text !default;
$kendo-selected-hover-border: rgba( $kendo-base-border, .1 ) !default;
$kendo-selected-hover-gradient: null !default;

// Generic styles

// Header
$kendo-component-header-bg: $kendo-base-bg !default;
$kendo-component-header-text: $kendo-base-text !default;
$kendo-component-header-border: $kendo-base-border !default;
$kendo-component-header-gradient: null !default;

// Validator
$kendo-invalid-bg: null !default;
$kendo-invalid-text: $kendo-color-error !default;
$kendo-invalid-border: $kendo-color-error !default;
$kendo-invalid-shadow: null !default;

$kendo-valid-bg: null !default;
$kendo-valid-text: $kendo-color-success !default;
$kendo-valid-border: $kendo-color-success !default;
$kendo-valid-shadow: null !default;

// Disabled Styling
$kendo-disabled-bg: null !default;
$kendo-disabled-text: k-map-get( $theme, disabled-text ) !default;
$kendo-disabled-border: null!default;
$kendo-disabled-opacity: .6 !default;
$kendo-disabled-filter: grayscale(.1) !default;

$kendo-disabled-styling: (
    bg: $kendo-disabled-bg,
    color: $kendo-disabled-text,
    border: $kendo-disabled-border,
    opacity: $kendo-disabled-opacity,
    filter: $kendo-disabled-filter,
) !default;
