@import "@progress/kendo-theme-default/scss/radio/_theme.scss";


@mixin kendo-radio--theme() {

    @include kendo-radio--theme-base();

    // Radio
    .k-radio-label {

        // Disabled state
        .k-radio:disabled + & {
            color: if($kendo-enable-color-system, k-color( subtle ), if( $kendo-is-dark-theme, $light-secondary-text, $dark-secondary-text ));
            opacity: 1;
            filter: none;
        }
    }

}
